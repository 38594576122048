import { ActionTypes } from "./actiontypes";

export const addVendorBasicDetails = (vendorData) =>{
    return {
        type:ActionTypes.ADD_VENDOR_BASIC_DETAILS,
        payload: vendorData
    }
}

export const addCompanyBasicDetails = (cmpData) =>{
    return {
        type:ActionTypes.ADD_COMPANY_BASIC_DETAILS,
        payload: cmpData
    }
}

export const addCompanyBankDetails = (cmpData) =>{
    return {
        type:ActionTypes.ADD_COMPANY_BANK_DETAILS,
        payload: cmpData
    }
}