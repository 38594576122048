import { VERIFY_OTP_REQUEST, VERIFY_OTP_SUCCESS, VERIFY_OTP_FAILURE } from '../actions/actiontypes';

// Initial state for OTP verification
const initialState = {
    loading: false,
    error: null,
    verificationData: null
};

// OTP verification reducer
const otpVerificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case VERIFY_OTP_REQUEST:
            return { ...state, loading: true, error: null };
        case VERIFY_OTP_SUCCESS:
            return { ...state, loading: false, verificationData: action.payload, error: null };
        case VERIFY_OTP_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export default otpVerificationReducer;
