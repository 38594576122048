import { FETCH_EVENT_TYPES_SUCCESS, FETCH_EVENT_TYPES_FAILURE } from '../actions/actiontypes';

const initialState = {
    eventTypes: [],
    error: null
};

const eventTypesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_EVENT_TYPES_SUCCESS:
            return {
                ...state,
                eventTypes: action.payload,
                error: null
            };
        case FETCH_EVENT_TYPES_FAILURE:
            return {
                ...state,
                eventTypes: [],
                error: action.error
            };
        default:
            return state;
    }
};

export default eventTypesReducer;
