
import {
    FETCH_CONTACT_DETAILS_REQUEST,
    FETCH_CONTACT_DETAILS_SUCCESS,
    FETCH_CONTACT_DETAILS_FAILURE,
    FETCH_BANK_DETAILS_REQUEST,
    FETCH_BANK_DETAILS_SUCCESS,
    FETCH_BANK_DETAILS_FAILURE,
    FETCH_COVERAGE_DETAILS_REQUEST,
    FETCH_COVERAGE_DETAILS_SUCCESS,
    FETCH_COVERAGE_DETAILS_FAILURE
} from '../actions/actiontypes';

const initialState = {
    contactDetails: [],
    bankDetails: [],
    coverageDetails: [],
    loading: false,
    error: null
};

const companyDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CONTACT_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_CONTACT_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                contactDetails: action.payload,
                error: null
            };
        case FETCH_CONTACT_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case FETCH_BANK_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_BANK_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                bankDetails: action.payload,
                error: null
            };
        case FETCH_BANK_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
         default:
            return state;
    }
};

export default companyDetailsReducer;
