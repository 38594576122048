// resendOtpReducer.js

import { RESEND_OTP_REQUEST, RESEND_OTP_SUCCESS, RESEND_OTP_FAILURE } from '../actions/actiontypes'; // Ensure the correct path to your actionTypes file

const initialState = {
    loading: false,
    error: null
};

const resendOtpReducer = (state = initialState, action) => {
    switch (action.type) {
        case RESEND_OTP_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case RESEND_OTP_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case RESEND_OTP_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
};

export default resendOtpReducer;
