import React, { useEffect, useState } from "react";
import "./LoginSignupDefault.scss";
import {
  Box,
  FormControl,
  FormGroup,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import LeftLogo from "./Component/LeftLogo";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm, trigger } from "react-hook-form";
import { vendorCreateAccountValidation } from "./validation";
import { useDispatch, useSelector } from "react-redux";
import {
  addCompanyBasicDetails,
  addVendorBasicDetails,
} from "../../redux/actions/companyActions";
import { toast } from "react-toastify";
import {
  companyBasicDetailsSignUpProcess,
  vendorBasicDetailsSignUpProcess,
} from "../../api/userApi";
import { BorderBottom, Visibility, VisibilityOff } from "@mui/icons-material";
import PhoneInput from "react-phone-input-2";
import LoaderComponent from "../../vendor/Content/Loader/loader";
import ErrorMessage from "../../utils/ErrorMessage";

//Styling with useStyle

const useStyles = makeStyles((theme) => ({
  margin80: {
    marginTop: "80px !Important",
  },
  margin50: {
    marginTop: "50px !Important",
  },
  margin20: {
    marginTop: "20px !Important",
  },
  timePickerRow: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1), // Adjust margin bottom as needed
  },
  labelMargin: {
    marginRight: theme.spacing(1), // Adjust margin right as needed
  },
  margin10: {
    marginTop: "10px !Important",
  },
  errors: {
    color: "red",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "16px",
    border: "2px solid #008E7A !Important",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const SignUpVendorDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles(); // Ensure you have useStyles defined
  const vendorData = useSelector((state) => state.companyReducer.vendorDetail);
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [inTouched, setinTouched] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    clearErrors,
    trigger,
    formState: { errors, dirtyFields },
  } = useForm({
    resolver: yupResolver(vendorCreateAccountValidation),
  });

  const handleBlur = async (fieldName) => {
    await trigger(fieldName);
  };

  const handleChangeImage = (e) => {
    const file = e.target.files[0];
    setValue("profileImage", file);
    setSelectedFileName(file ? file.name : "");
  };

  useEffect(() => {
    if (inTouched && !selectedFileName) {
      setError("profileImage", {
        type: "manual",
        message: "Profile image is required",
      });
    }
  }, [inTouched, selectedFileName]);

  const handlePhoneChange = (value) => {
    setValue("mobile", String(value));
  };

  const preventLeadingSpaces = (event, setValueFunc) => {
    const trimmedValue = event.target.value.replace(/^\s+/, "");
    setValueFunc(trimmedValue);
    clearErrors(event.target.name);
  };

  const formSubmitNext = async (data) => {
    setIsSubmitting(true);
    setIsLoading(true);
    console.log(data);

    // Validate the profileImage field before proceeding
    if (!data.profileImage || !(data.profileImage instanceof File)) {
      setError("profileImage", {
        type: "manual",
        message: "Profile image is required",
      });
      setIsSubmitting(false);
      setIsLoading(false);
      return;
    }

    const formData = new FormData();

    formData.append("emailId", data.emailId);
    formData.append("firstname", data.firstname);
    formData.append("lastname", data.lastname);
    formData.append("mobile", data.mobile);
    formData.append("password", data.password);
    formData.append("role", "vendor");
    formData.append("profileImage", data.profileImage);

    for (const [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }

    // Call the API
    dispatch(addVendorBasicDetails(formData));
    await callAPI(formData);
  };

  const callAPI = async (data) => {
    try {
      const user = await vendorBasicDetailsSignUpProcess(data);
      if (user) {
        localStorage.setItem("EM_Vendor", JSON.stringify(user));
        dispatch({ type: "EMPTY_VENDOR_BASIC_DETAILS" });
        dispatch(addVendorBasicDetails(user));

        if (user.isAlreadyRegistered) {
          toast.error(user?.message);
          if (!user.data.companyDetail) {
            navigate("/signup-company-details");
          } else if (!user.data.contactDetail) {
            navigate("/signup-contact-details");
          } else if (!user.data.bankDetail) {
            navigate("/signup-bank-details");
          } else if (!user.data.itemDetail) {
            navigate("/signup-item-details");
          } else {
            navigate("/loginv2");
          }
        } else {
          toast.success(user?.message);
          navigate("/signup-company-details");
        }
      }
    } catch (err) {
      toast.error(err?.message[0]);
    } finally {
      setIsSubmitting(false);
      setIsLoading(false);
    }
  };
  const [isFilled, setIsFilled] = useState(false);
  return (
    <Box className="login-signup-default">
      <Box className="d-flex flex-wrap">
        <LeftLogo />
        <Box className="col-xxl-7 col-sm-8 col-12 right-box-outer">
          {isLoading ? (
            <LoaderComponent />
          ) : (
            <Box className="container h-100 d-flex align-items-lg-center justify-content-center py-4">
              <Box className="right">
                <Box className="tagline">"Bring Your Event to Life"</Box>
                <h1>Vendor Details</h1>
                <Box
                  component="form"
                  onSubmit={handleSubmit(formSubmitNext)}
                  className="form-box height-450"
                >
                  <TextField
                    className="customInputFilledDesign"
                    {...register("firstname")}
                    id="filled-basic"
                    fullWidth
                    label="First Name"
                    autoComplete="off"
                    variant="filled"
                    onChange={(e) =>
                      preventLeadingSpaces(e, (value) =>
                        setValue("firstname", value)
                      )
                    }
                    onBlur={() => handleBlur("firstname")}
                  />
                  <ErrorMessage message={errors.firstname?.message} />

                  {/* {errors?.firstname && (
                    <p className={classes.errors}>
                      {errors?.firstname?.message}
                    </p>
                  )} */}

                  <TextField
                    className="customInputFilledDesign"
                    {...register("lastname")}
                    id="outlined-basic"
                    label="Last Name"
                    variant="filled"
                    autoComplete="off"
                    onChange={(e) =>
                      preventLeadingSpaces(e, (value) =>
                        setValue("lastname", value)
                      )
                    }
                    onBlur={() => handleBlur("lastname")}
                  />

                  <ErrorMessage message={errors.lastname?.message} />

                  <TextField
                    className="customInputFilledDesign"
                    {...register("emailId")}
                    id="outlined-basic"
                    label="Email Id"
                    variant="filled"
                    autoComplete="off"
                    onChange={(e) =>
                      preventLeadingSpaces(e, (value) =>
                        setValue("emailId", value)
                      )
                    }
                    onBlur={() => handleBlur("emailId")}
                  />
                  {/* {errors?.emailId && (
                    <p className={classes.errors}>{errors?.emailId?.message}</p>
                  )} */}
                  <ErrorMessage message={errors.emailId?.message} />

                  <Box className="rounded-3">
                    <TextField
                      className="customInputFilledDesign"
                      {...register("password")}
                      id="outlined-adornment-password"
                      label="Password"
                      variant="filled"
                      onChange={(e) =>
                        preventLeadingSpaces(e, (value) =>
                          setValue("password", value)
                        )
                      }
                      onBlur={() => handleBlur("password")}
                      fullWidth
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{ cursor: "pointer", height: "100%" }}
                          >
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <ErrorMessage message={errors.password?.message} />

                  {/* {errors?.password && (
                    <p className={classes.errors}>
                      {errors?.password?.message}
                    </p>
                  )} */}

                  <Controller
                    name="mobile"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <PhoneInput
                        country={`${isFilled ? "ae" : ""}`} // 'ae' will be added in the future
                        value={field.value}
                        containerClass={`customInputFilledDesign mobileNumberDesign ${
                          isFilled ? "filled-input" : ""
                        }`}
                        inputClass="phone-input-field"
                        onBlur={() => handleBlur("mobile")}
                        onChange={(value) => {
                          field.onChange(value);
                          handlePhoneChange(value);
                          setIsFilled(!!value); // Set isFilled to true if value is not empty
                        }}
                        onFocus={() => setIsFilled(true)} // Add filled-input class on focus
                        inputProps={{
                          name: "mobile",
                          required: false,
                          autoComplete: "off",
                        }}
                        placeholder=" "
                      />
                    )}
                  />
                  {/* {errors?.mobile && (
                    <p className={classes.errors}>{errors?.mobile?.message}</p>
                  )} */}
                  <ErrorMessage message={errors.mobile?.message} />

                  <TextField
                    id="filled-basic"
                    label="Profile Image"
                    name="profileImage"
                    variant="filled"
                    value={selectedFileName}
                    onBlur={() => handleBlur("profileImage")}
                    className="customInputFilledDesign"
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <input
                            id="profileImage"
                            type="file"
                            style={{ display: "none" }}
                            onBlur={() => handleBlur("profileImage")}
                            onChange={(e) => {
                              handleChangeImage(e);
                              setinTouched(true);
                            }}
                            accept="image/*"
                          />
                          <label htmlFor="profileImage">
                            <IconButton
                              component="span"
                              sx={{
                                width: "38px",
                                boxShadow:
                                  "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
                                "&:hover": {
                                  boxShadow:
                                    "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
                                },
                                position: "relative",
                                bottom: "8px",
                                backgroundColor: "#fff",
                              }}
                            >
                              <svg
                                width="16"
                                height="22"
                                viewBox="0 0 16 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5 7.25H3.5C2.90326 7.25 2.33097 7.48705 1.90901 7.90901C1.48705 8.33097 1.25 8.90326 1.25 9.5V18.5C1.25 19.0967 1.48705 19.669 1.90901 20.091C2.33097 20.5129 2.90326 20.75 3.5 20.75H12.5C13.0967 20.75 13.669 20.5129 14.091 20.091C14.5129 19.669 14.75 19.0967 14.75 18.5V9.5C14.75 8.90326 14.5129 8.33097 14.091 7.90901C13.669 7.48705 13.0967 7.25 12.5 7.25H11M11 4.25L8 1.25M8 1.25L5 4.25M8 1.25V14"
                                  stroke="#757575"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </IconButton>
                          </label>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <ErrorMessage message={errors.profileImage?.message} />

                  {/* {errors?.profileImage && (
                    <p className={classes.errors}>
                      {errors?.profileImage?.message}
                    </p>
                  )} */}

                  <FormGroup className="submit-box mt-4">
                    <Box className="d-flex flex-wrap gap-2 gap-xxl-4">
                      <button
                        className="default-btn white-bg"
                        onClick={() => navigate("/loginv2")}
                        disabled={isSubmitting}
                      >
                        Cancel
                      </button>
                      <button
                        className="default-btn green-bg"
                        type="submit"
                        disabled={isSubmitting}
                        onClick={() => {
                          setinTouched(true);
                        }}
                      >
                        Next
                      </button>
                    </Box>
                  </FormGroup>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SignUpVendorDetails;
