import { createStore, applyMiddleware, compose } from "redux";
import { thunk } from 'redux-thunk';
import rootReducer from '../redux/reducers/index'
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
    key: "root",
    storage
    // whitelist: ["USER_REDUCER", "PROPERTY_REDUCER", "resaleInfoReducer", "resaleDetailsReducer", "resaleSingleReducer", "tokenReducer", "resaleCardReducer"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middlewares = [thunk];
const enhancer = composeEnhancers(applyMiddleware(...middlewares));
export let store = createStore(persistedReducer, enhancer)

export let persistor = persistStore(store);