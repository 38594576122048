import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Grid, Typography } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LockIcon from "@mui/icons-material/Lock";


const useStyles = makeStyles((theme) => ({
    mainBox: {
        height: "100vh",
        width: "60%",
        background: "black",
        position: "absolute",
        left: "0",
        top: "0",
        opacity: "0.7",
        zIndex : "3"
    },
    whiteBox: {
        position: "absolute",
        right: "0",
        top: "0",
        height: "100vh",
        width: "40%",
        background: "white",
        opacity: "1",
        minHeight: "400px", 
        overflowY: "auto",
        zIndex : "3"
    },
    closeArrowBox: {
        padding: "40px",
        marginTop: "50px !Important"
    },
    editProfileText: {
        fontFamily: "Mulish !Important",
        fontWeight: "700 !Important",
        fontSize: "14px !Important",
        marginLeft: "15px !Important",
        marginTop: "2px !Important"
    },
    hover: {
        "&:hover": {
            cursor: "pointer",
            opacity: "0.7"
        }
    },
    borderBox: {
        border: "1px solid rgba(221, 216, 216, 1) !Important",
        margin: "40px !Important",
        padding: "40px !Important"
    },
    greyText: {
        fontFamily: "Mulish !Important",
        fontWeight: "400 !Important",
        fontSize: "16px !Important",
        lineHeight: "24px",
        color: "rgba(99, 99, 99, 1)"
    },
    margin20: {
        marginTop: "20px !Important",
    },
    width100: {
        width: "100%",
    },
    mt30: {
        marginTop: "30px"
    },
    button: {
        width: "100% !Important",
        marginTop: "40px !Important",
        padding: "15px 10px !Important",
        color: "white !Important",
        background: "rgba(0, 142, 122, 1) !Important",
        border: "none !Important",
        fontFamily : "Mulish !Important " , 
        fontSize : "18px !Important",
        fontWeight : "700 !Important"
      },
}))

function ChangePassword({ handleChangePasswordEditProfile }) {
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);
    const [userCredential, setUserCredential] = useState({
        password: "",
        rePassword: "",
    });
    const [upperAndLowerCase, setUpperAndLowerCase] = useState(false);
    const [numberAndSybol, setNumberAndSybol] = useState(false);
    const [length, setLength] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    useEffect(() => {

        const upperAndLowerCaseReg = /^(?=.*[a-z])(?=.*[A-Z])/;
        if (upperAndLowerCaseReg.test(userCredential?.password)) {
            setUpperAndLowerCase(true);
        } else
            setUpperAndLowerCase(false);

        const numberAndSybolReg = /^(?=.*[0-9])(?=.*[!@#\$%\^&\*])/; //eslint-disable-line
        if (numberAndSybolReg.test(userCredential?.password)) {
            setNumberAndSybol(true)
        } else
            setNumberAndSybol(false)

        const lengthReg = /^.{8,}$/;
        if (lengthReg.test(userCredential?.password)) {
            setLength(true);
        } else
            setLength(false);

    }, [userCredential]);

    return (
        <>
            <Box className={classes.mainBox}>
            </Box>
            <Box className={classes.whiteBox}>
                <Grid spacing={1} container className={classes.closeArrowBox}>
                    <CloseOutlinedIcon className={classes.hover} onClick={() => { handleChangePasswordEditProfile() }} />
                    <Typography className={classes.editProfileText}>Change Password</Typography>
                </Grid>
                <Grid className={classes.borderBox}>
                    <Typography className={classes.greyText}>
                        Change your password by enter your current
                    </Typography>
                    <Typography className={classes.greyText}>
                        password
                    </Typography>
                    <Box className={classes.mt30}>
                        <FormControl
                            className={`${classes.margin20} ${classes.width100}`}
                            variant="outlined"
                        >
                            <InputLabel htmlFor="outlined-adornment-password">
                                Current Password
                            </InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={showPassword ? "text" : "password"}
                                onChange={(e) =>
                                    setUserCredential({
                                        password: e.target.value,
                                        rePassword: userCredential?.rePassword,
                                    })
                                }
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                            />
                        </FormControl>
                        <FormControl
                            className={`${classes.margin20} ${classes.width100}`}
                            variant="outlined"
                        >
                            <InputLabel htmlFor="outlined-adornment-password">
                                Password
                            </InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={showPassword ? "text" : "password"}
                                onChange={(e) =>
                                    setUserCredential({
                                        password: e.target.value,
                                        rePassword: userCredential?.rePassword,
                                    })
                                }
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                            />
                        </FormControl>
                        <Box className={`${classes.d_flex} ${classes.margin20}`}>
                            <LockIcon />
                            <Box>
                                <Typography className={classes.p2}>
                                    Your Password needs to:
                                </Typography>
                                {upperAndLowerCase ? (
                                    <Typography
                                        className={classes.p2}
                                        style={{ color: "green" }}
                                    >
                                        Include both lower and upper case characters
                                    </Typography>
                                ) : (
                                    <Typography className={classes.p2} style={{ color: "red" }}>
                                        Include both lower and upper case characters
                                    </Typography>
                                )}
                                {numberAndSybol ? (
                                    <Typography
                                        className={classes.p2}
                                        style={{ color: "green" }}
                                    >
                                        Include at least one number and symbol
                                    </Typography>
                                ) : (
                                    <Typography className={classes.p2} style={{ color: "red" }}>
                                        Include at least one number and symbol
                                    </Typography>
                                )}
                                {length ? (
                                    <Typography
                                        className={classes.p2}
                                        style={{ color: "green" }}
                                    >
                                        Be at least 8 character long
                                    </Typography>
                                ) : (
                                    <Typography className={classes.p2} style={{ color: "red" }}>
                                        Be at least 8 character long
                                    </Typography>
                                )}
                                {

                                    userCredential?.password === userCredential?.rePassword
                                        ? (
                                            <Typography
                                                className={classes.p2}
                                                style={{ color: "green" }}
                                            >
                                                Pass must match
                                            </Typography>
                                        ) : (
                                            <Typography className={classes.p2} style={{ color: "red" }}>
                                                Pass must match
                                            </Typography>
                                        )}
                            </Box>
                        </Box>
                        <FormControl
                            className={`${classes.margin20} ${classes.width100}`}
                            variant="outlined"
                        >
                            <InputLabel htmlFor="outlined-adornment-password">
                                Confirm Password
                            </InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={showPassword ? "text" : "password"}
                                onChange={(e) =>
                                    setUserCredential({
                                        password: userCredential?.password,
                                        rePassword: e.target.value,
                                    })
                                }
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                            />
                        </FormControl>
                        <Button
                            className={classes.button}
                            onClick={
                                () => {

                                }
                            }
                        >
                            Submit
                        </Button>
                    </Box>
                </Grid>
            </Box>
        </>
    )
}

export default ChangePassword