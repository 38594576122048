import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from "@mui/material"
import MainProfilePopUp from './MainProfilePopUp';
import EditProfile from './EditProfile';
import ChangePassword from './ChangePassword';
import ChangeEmail from './ChangeEmail';
import Language from './Language';

function ProfilePopUp({ name, user, logout, profileStateHandler, profileState }) {
    const navigate = useNavigate();
    const [editProfileState, setEditProfileState] = useState(false);
    const [changePasswordState, setChangePasswordState] = useState(false);
    const [changeEmailState, setChangeEmailState] = useState(false);
    const [languageState, setLanguageState] = useState(false);

    const crossButtonEditProfile = () => {
        // setEditProfileState(!editProfileState);
        navigate("/user/profile");
    }
    const [renderingComponent, setRenderingComponent] = useState(<MainProfilePopUp />)

    const handleChangePasswordEditProfile = () => {
        setChangePasswordState(!changePasswordState)
        crossButtonEditProfile();
    }


    const scrollOff = () => {
        document.body.style.overflow = "hidden";
    }

    const scrollOn = () => {
        document.body.style.overflow = "auto";
    }

    const handleChangeEmailEditProfile = () => {
        crossButtonEditProfile();
        setChangeEmailState(!changeEmailState);
    }

    const handleLanguage = () => {
        setLanguageState(!languageState);
    }

    useEffect(() => {
        if (editProfileState) {
            setRenderingComponent(<EditProfile
                crossButtonEditProfile={crossButtonEditProfile}
                handleChangePasswordEditProfile={handleChangePasswordEditProfile}
                handleChangeEmailEditProfile={handleChangeEmailEditProfile}
            />)
            scrollOff();
        }
        else if (changePasswordState) {
            setRenderingComponent(<ChangePassword handleChangePasswordEditProfile={handleChangePasswordEditProfile} />);
        }

        else if (changeEmailState) {
            setRenderingComponent(<ChangeEmail handleChangeEmailEditProfile={handleChangeEmailEditProfile} />)
        }
        else if (languageState) {
            setRenderingComponent(<Language handleLanguage={handleLanguage} />);
            scrollOff();
        }
        else {
            setRenderingComponent(<MainProfilePopUp
                name={name}
                user={user}
                logout={logout}
                crossButtonEditProfile={crossButtonEditProfile}
                handleLanguage={handleLanguage}
            />);
            scrollOn();
        }
        // eslint-disable-next-line
    }, [editProfileState, changePasswordState, changeEmailState, languageState]);

    return (
        <>
            <Modal
                open={profileState}
                onClose={profileStateHandler}
                aria-labelledby="profile-popup-modal"
                aria-describedby="profile-popup-modal-description"
            >
                {renderingComponent}
            </Modal>
        </>
    )
}

export default ProfilePopUp
