// reducer.js

import {
    FETCH_VENDOR_PROFILE_REQUEST,
    FETCH_VENDOR_PROFILE_SUCCESS,
    FETCH_VENDOR_PROFILE_FAILURE,
    UPDATE_VENDOR_PROFILE_REQUEST,
    UPDATE_VENDOR_PROFILE_SUCCESS,
    UPDATE_VENDOR_PROFILE_FAILURE
} from '../actions/actiontypes';

const initialState = {
    profile: {},
    loading: false,
    error: null
};

const vendorProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_VENDOR_PROFILE_REQUEST:
        case UPDATE_VENDOR_PROFILE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_VENDOR_PROFILE_SUCCESS:
        case UPDATE_VENDOR_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                profile: action.payload,
                error: null
            };
        case FETCH_VENDOR_PROFILE_FAILURE:
        case UPDATE_VENDOR_PROFILE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};

export default vendorProfileReducer;
