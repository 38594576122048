import {UPDATE_ID} from "../actions/actiontypes"

const initialState = {
    id: null
};

const feedbackReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_ID':
            return {
                ...state,
                id: action.payload // Assuming the payload of UPDATE_ID is the new ID
            };
        default:
            return state;
    }
};

export default feedbackReducer;
