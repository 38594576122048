import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Grid, Typography } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const useStyles = makeStyles((theme) => ({
  mainBox: {
    height: "100vh",
    width: "60%",
    background: "black",
    position: "absolute",
    left: "0",
    top: "0",
    opacity: "0.7",
    zIndex : "3"
  },
  whiteBox: {
    position: "absolute",
    right: "0",
    top: "0",
    height: "100vh",
    width: "40%",
    background: "white",
    opacity: "1" ,
    minHeight: "400px", 
    overflowY: "auto" ,
    zIndex : "3"
  },
  closeArrowBox: {
    padding: "40px",
    marginTop: "50px !Important"
  },
  editProfileText: {
    fontFamily: "Mulish !Important",
    fontWeight: "700 !Important",
    fontSize: "14px !Important",
    marginLeft: "15px !Important",
    marginTop: "2px !Important"
  },
  middleBox: {
    padding: "55px !Important"
  },
  themeColor: {
    color: "rgba(0, 142, 122, 1)"
  },
  opacityText: {
    fontFamily: "Mulish !Important",
    fontWeight: "400 !Important",
    fontSize: "14px !Important",
    color: "rgba(171, 171, 171, 1) !Important"
  },
  text2: {
    fontFamily: "Mulish !Important",
    fontWeight: "600 !Important",
    fontSize: "16px !Important",
  },
  mt15: {
    marginTop: "15px !Important"
  },
  mt30: {
    marginTop: "15px !Important"
  },
  hover: {
    "&:hover": {
      cursor: "pointer",
      opacity: "0.7"
    }
  },
  opacityHr: {
    opacity: "0.2",
    width: "100%",
    margin: "20px auto !Important"
  },
  button: {
    width: "100% !Important",
    background: "rgba(0, 142, 122, 1) !Important",
    color: "white !Important",
    margin: "auto 40px",
    "text-transform": "capitalize !Important",
    fontFamily: "Mulish !Important",
    fontWeight: "700 !Important",
    fontSize: "14px !Important",
    padding: "15px !Important"
  },
  buttonBox: {
    margin: "auto 40px !Important"
  },
  notificationText: {
    fontFamily: "Mulish !Important",
    fontWeight: "800 !Important",
    fontSize: "20px !Important",
    lineHeight: "33px !Important",
    color: "white !Important"
  },
  notificationBox: {
    background: "rgba(0, 142, 122, 1)",
    padding: "5px 5px 5px 20px", //    t,r,b,l
    margin: "0px !Important"
  },
  notificationIcon: {
    color: "white",
    fontSize: "40px !Important",
    margin: "auto !Important"
  }
}))





function EditProfile({ handleChangePasswordEditProfile, crossButtonEditProfile , handleChangeEmailEditProfile}) {
  const classes = useStyles();

 

  return (
    <>
      <Box className={classes.mainBox}>
      </Box>
      <Box className={classes.whiteBox}>
        <NotificationPassword classes={classes} />
        <NotificationEmail classes={classes} />
        <Grid spacing={1} container className={classes.closeArrowBox}>
          <CloseOutlinedIcon className={classes.hover} onClick={() => crossButtonEditProfile()} />
          <Typography className={classes.editProfileText}>Edit Profile</Typography>
        </Grid>
        <Grid className={classes.middleBox}>
          <Grid spacing={1} container>
            <Grid md={1}>
              <EmailOutlinedIcon className={`${classes.themeColor}`} />
            </Grid>
            <Grid spacing={1} container md={11}>
              <Grid md={10}>
                <Typography className={classes.opacityText}>
                  Email
                </Typography>
                <Typography className={classes.text2}>
                  mayank7@gmail.com
                </Typography>
              </Grid>
              <Grid md={2}>
                <Typography className={`${classes.text2} ${classes.themeColor} ${classes.mt15} ${classes.hover}`} onClick={() => {handleChangeEmailEditProfile()}}>Change</Typography>
              </Grid>
              <hr className={classes.opacityHr} />
            </Grid>
          </Grid>
          <Grid spacing={1} container className={classes.mt30}>
            <Grid md={1}>
              <EmailOutlinedIcon className={`${classes.themeColor}`} />
            </Grid>
            <Grid spacing={1} container md={11}>
              <Grid md={10}>
                <Typography className={classes.opacityText}>
                  First Name
                </Typography>
                <Typography className={classes.text2}>
                  Mayank
                </Typography>
              </Grid>
              <Grid md={2}>
                <Typography className={`${classes.text2} ${classes.themeColor} ${classes.mt15} ${classes.hover}`}>Change</Typography>
              </Grid>
              <hr className={classes.opacityHr} />
            </Grid>
          </Grid>
          <Grid spacing={1} container className={classes.mt30}>
            <Grid md={1}>
              <PermIdentityOutlinedIcon className={`${classes.themeColor}`} />
            </Grid>
            <Grid spacing={1} container md={11}>
              <Grid md={10}>
                <Typography className={classes.opacityText}>
                  Last Name
                </Typography>
                <Typography className={classes.text2}>
                  Bharti
                </Typography>
              </Grid>
              <Grid md={2}>
                <Typography className={`${classes.text2} ${classes.themeColor} ${classes.mt15} ${classes.hover}`}>Change</Typography>
              </Grid>
              <hr className={classes.opacityHr} />
            </Grid>
          </Grid>
          <Grid spacing={1} container className={classes.mt30}>
            <Grid md={1}>
              <EmailOutlinedIcon className={`${classes.themeColor}`} />
            </Grid>
            <Grid spacing={1} container md={11}>
              <Grid md={10}>
                <Typography className={classes.opacityText}>
                  Password
                </Typography>
                <Typography className={classes.text2}>
                  **********
                </Typography>
              </Grid>
              <Grid md={2}>
                <Typography className={`${classes.text2} ${classes.themeColor} ${classes.mt15} ${classes.hover}`} onClick={() => handleChangePasswordEditProfile()}>Change</Typography>
              </Grid>
              <hr className={classes.opacityHr} />
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.buttonBox}>
          <Button className={classes.button}>Delete</Button>
        </Grid>
      </Box>
    </>
  )
}

const NotificationPassword = ({ classes }) => (
  <Grid container spacing={1} className={classes.notificationBox}>
    <Grid md={11}><Typography className={classes.notificationText}>
      Password Changed successfully!
    </Typography></Grid>
    <Grid md={1}>
      <CheckCircleIcon className={classes.notificationIcon} />
    </Grid>
  </Grid >
)
const NotificationEmail = ({ classes }) => (
  <Grid container spacing={1} className={classes.notificationBox}>
    <Grid md={11}><Typography className={classes.notificationText}>
      Email Changed successfully!
    </Typography></Grid>
    <Grid md={1}>
      <CheckCircleIcon className={classes.notificationIcon} />
    </Grid>
  </Grid >
)

export default EditProfile