import * as React from "react";
import { useForm, Controller } from "react-hook-form";
import Dialog from "@mui/material/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import CancelIcon from "@mui/icons-material/Cancel";
import { Typography } from "@mui/material";
import { Checkbox, FormControlLabel, FormControl } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { TextField } from "@mui/material";
import "../assert/css/languageComponent.css";
import { cancelOrder } from "../redux/actions/orderActions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useUserContext from "../contexts/userContext";
import home from "../transalation/home.json";
import { useEffect } from "react";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  popUp: {
    padding: "30px !Important",
    borderRadius: "10px !Important",
    [theme.breakpoints.down("xs")]: {
      padding: "10px !Important",
    },
  },
  marginRight: {
    marginRight: "10px", // Adjust the value as per your preference
  },
  h1: {
    fontFamily: "Mulish !Important",
    fontWeight: "600 !Important",
    fontSize: "18px !Important",
    textAlign: "center",
  },
  greenColor: {
    color: "rgba(0, 142, 122, 1) !Important",
  },
  d_flex: {
    display: "flex",
  },
  justifyEnd: {
    justifyContent: "end !Important",
  },
  justifyCenter: {
    justifyContent: "center !Important",
  },
  p1: {
    fontFamily: "Mulish !Important",
    fontWeight: "400 !Important",
    fontSize: "15px !Important",
    lineHeight: "22px !Important",
    marginTop: "20px !Important",
    textAlign: "center",
    padding: "0px 50px",
    [theme.breakpoints.down("xs")]: {
      padding: "0px 10px",
    },
  },
  p2: {
    fontFamily: "Mulish !Important",
    fontWeight: "600 !Important",
    fontSize: "12px !Important",
    textAlign: "center !Important",
    lineHeight: "20px !Important",
  },
  button: {
    maxWidth: "250px",
    width: "100%",
    marginTop: "40px",
    padding: "15px 10px",
    color: "white",
    background: "rgba(0, 142, 122, 1)",
    border: "none",
    borderRadius: "20px",
  },
  button1: {
    maxWidth: "250px",
    width: "100%",
    marginTop: "40px",
    padding: "15px 10px",
    color: "rgba(0, 142, 122, 1)",
    border: "2px solid rgba(0, 142, 122, 1)",
    background: "white !Important",
    borderRadius: "20px",
  },
  inputCancel: {
    margin: "0 auto",
    width: "100%",
    marginBottom: "20px",
  },
  mb20: {
    marginBottom: "20px",
  },
  greyColor: {
    color: "rgba(86, 86, 86, 1)",
  },
  dialogOuterBox: {
    "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
      maxWidth: "450px",
      width: "70%",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
  },
  mt30: {
    marginTop: "30px",
  },
  themeColor: {
    color: "rgba(0, 142, 122, 1)",
  },
  crossButton: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "30px",
      marginRight: "30px",
    },
  },
}));

function Logout({ open, handleClickOpen }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { language } = useUserContext();

  const [content, setContent] = useState({});

  useEffect(() => {
    if (language === "english") {
      setContent(home.english);
    } else {
      setContent(home.arabic);
    }
  }, [language]);

  const handleLogout = () => {
    // Remove user data from localStorage
    localStorage.removeItem("EM_User");

    // Close the modal
    handleClickOpen(false);

    // Navigate to the home page
    navigate("/");
  };

  return (
    <div>
      <Dialog open={open} className={classes.dialogOuterBox}>
        <Box className={classes.popUp}>
          <Box className={`${classes.d_flex} ${classes.justifyEnd}`}>
            <CancelIcon
              className={`${classes.crossButton} ${classes.greenColor}`}
              onClick={() => handleClickOpen()}
            />
          </Box>
          <Typography className={classes.h1}>{content?.logoutcheck}</Typography>
          <Typography
            className={`${classes.greyColor} ${classes.p1} ${classes.mb20}`}
          >
            {content?.logoutpop}
          </Typography>
          <Box className={`${classes.d_flex} ${classes.justifyCenter}`}>
            <button
              className={`${classes.button1} ${classes.marginRight}`}
              onClick={() => handleClickOpen()}
            >
              {content?.Cancel}
            </button>
            <button className={classes.button} onClick={handleLogout}>
              {content?.logout}
            </button>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
}

export default Logout;
