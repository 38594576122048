import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Grid, Typography } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { MuiOtpInput } from 'mui-one-time-password-input';

const useStyles = makeStyles((theme) => ({
    mainBox: {
        height: "100vh",
        width: "60%",
        background: "black",
        position: "absolute",
        left: "0",
        top: "0",
        opacity: "0.7",
        zIndex : "3"
    },
    whiteBox: {
        position: "absolute",
        right: "0",
        top: "0",
        height: "100vh",
        width: "40%",
        background: "white",
        opacity: "1" ,
        minHeight: "400px", 
        overflowY: "auto",
        zIndex : "3"
    },
    closeArrowBox: {
        padding: "40px",
        marginTop: "50px !Important"
    },
    editProfileText: {
        fontFamily: "Mulish !Important",
        fontWeight: "700 !Important",
        fontSize: "14px !Important",
        marginLeft: "15px !Important",
        marginTop: "2px !Important"
    },
    hover: {
        "&:hover": {
            cursor: "pointer",
            opacity: "0.7"
        }
    },
    borderBox: {
        border: "1px solid rgba(221, 216, 216, 1) !Important",
        margin: "40px !Important",
        padding: "40px !Important"
    },
    greyText: {
        fontFamily: "Mulish !Important",
        fontWeight: "600 !Important",
        fontSize: "16px !Important",
        lineHeight: "24px",
        color: "rgba(99, 99, 99, 1)"
    },
    margin20: {
        marginTop: "20px !Important",
    },
    width100: {
        width: "100%",
    },
    button: {
        width: "100% !Important",
        marginTop: "300px !Important",
        padding: "15px 10px !Important",
        color: "white !Important",
        background: "rgba(0, 142, 122, 1) !Important",
        border: "none !Important",
        fontFamily: "Mulish !Important ",
        fontSize: "18px !Important",
        fontWeight: "700 !Important"
    },
    mt30: {
        marginTop: "30px"
    },
    input: {
        border: "1px solid rgba(221, 216, 216, 1) !Important",
        outline: "none",
        borderRadius: "5px",
        fontFamily: "Mulish !Important ",
        fontSize: "16px !Important",
        fontWeight: "500 !Important",
        padding: "20px",
        width: "calc(100% - 40px)"
    },
    themeColorText : {
        color : "rgba(0, 142, 122, 1) !Important"
    },
    roundeCircle: {
        '& .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root': {
          borderRadius: "100px !important",
          paddingTop: "20.5px !important",
          paddingBottom: "20.5px !important"
        }
      }
}))


function ChangeEmail({ handleChangeEmailEditProfile }) {
    const classes = useStyles();
    const [otpSentSuccess , setOtpSentSuccess] = useState(false);
    return (
        <>
        {
            otpSentSuccess
            ?
            <VerificationCode classes={classes} handleChangeEmailEditProfile={handleChangeEmailEditProfile} />
            :
            <ChangeEmailSub 
            classes={classes} 
            handleChangeEmailEditProfile={handleChangeEmailEditProfile} 
            setOtpSentSuccess={setOtpSentSuccess}
            />
        }
        </>
    )
}

export default ChangeEmail

const VerificationCode = ({ classes, handleChangeEmailEditProfile }) => {
    const [otp, setOtp] = React.useState('');

    const handleChange = (newValue) => {
        setOtp(newValue)
      }
    return (
        <>
            <Box className={classes.mainBox}>
            </Box>
            <Box className={classes.whiteBox}>
                <Grid spacing={1} container className={classes.closeArrowBox}>
                    <CloseOutlinedIcon className={classes.hover} onClick={() => { handleChangeEmailEditProfile() }} />
                    <Typography className={classes.editProfileText}>Verification Code</Typography>
                </Grid>
                <Grid className={classes.borderBox}>
                    <Typography className={classes.greyText}>
                    Enter the code we just sent to you on
                    </Typography>
                    <Typography className={`${classes.greyText} ${classes.themeColorText}`}>
                    mayank@123.gmail
                    </Typography>
                    <Box className={`${classes.mt30} ${classes.width100}`}>
                    <MuiOtpInput length={5} value={otp} onChange={handleChange} className={`${classes.roundeCircle}`} />
                        <Button
                            className={classes.button}
                            onClick={
                                () => {

                                }
                            }
                        >
                            Verify
                        </Button>
                    </Box>
                </Grid>
            </Box>
        </>
    )
}

const ChangeEmailSub = ({ classes, handleChangeEmailEditProfile , setOtpSentSuccess}) => {
    return (
        <>
            <Box className={classes.mainBox}>
            </Box>
            <Box className={classes.whiteBox}>
                <Grid spacing={1} container className={classes.closeArrowBox}>
                    <CloseOutlinedIcon className={classes.hover} onClick={() => { handleChangeEmailEditProfile() }} />
                    <Typography className={classes.editProfileText}>Change Email</Typography>
                </Grid>
                <Grid className={classes.borderBox}>
                    <Typography className={classes.greyText}>
                        Enter email and we'll send a reset code to you
                    </Typography>
                    <Box className={`${classes.mt30} ${classes.width100}`}>
                        <input className={`${classes.margin20} ${classes.input}`} placeholder='Email' />
                        <Button
                            className={classes.button}
                            onClick={
                                () => {
                                    setOtpSentSuccess(true)
                                }
                            }
                        >
                            Submit
                        </Button>
                    </Box>
                </Grid>
            </Box>
        </>
    )
}