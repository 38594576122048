// reducers.js
import {
    FETCH_SECTION_DETAILS_REQUEST,
    FETCH_SECTION_DETAILS_SUCCESS,
    FETCH_SECTION_DETAILS_FAILURE,
    ADD_SECTION_REQUEST,
    ADD_SECTION_SUCCESS,
    ADD_SECTION_FAILURE,
    UPDATE_SECTION_REQUEST,
    UPDATE_SECTION_SUCCESS,
    UPDATE_SECTION_FAILURE,
    DELETE_SECTION_FAILURE,
    DELETE_SECTION_SUCCESS,
    DELETE_SECTION_REQUEST,
    DELETE_ITEM_SUCCESS,
    DELETE_ITEM_REQUEST
} from '../actions/actiontypes';

const initialState = {
    items: [],
    loading: false,
    error: ''
};

const sectionReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SECTION_DETAILS_REQUEST:
        case ADD_SECTION_REQUEST:
        case DELETE_SECTION_REQUEST:
        case DELETE_ITEM_REQUEST:
            return {
                ...state,
                loading: true,
                error: ''
            };
        case FETCH_SECTION_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payload,
                error: ''
            };
        case ADD_SECTION_SUCCESS:
        case DELETE_SECTION_SUCCESS:
        case DELETE_ITEM_SUCCESS:
            return {
                ...state,
                loading: false,
                error: ''
            };
        case FETCH_SECTION_DETAILS_FAILURE:
        case ADD_SECTION_FAILURE:
        case DELETE_SECTION_FAILURE:
        case DELETE_ITEM_REQUEST:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case UPDATE_SECTION_REQUEST:
            return {
                ...state,
                loading: true,
                error: ''
            };
        case UPDATE_SECTION_SUCCESS:
            return {
                ...state,
                loading: false,
                error: ''
            };
        case UPDATE_SECTION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        default:
            return state;
    }
};

export default sectionReducer;
