import { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";

const VendorGuard = (props) => {
    const { children } = props;
    const location = useLocation();
    const [requestedLocation, setRequestLocation] = useState(null);
    let token = localStorage.getItem("EM_User");
    token = JSON.parse(token);
    let userData = token?.data;
    token = token?.token;

    if (token === undefined) {
        if (location.pathname !== requestedLocation) {
            setRequestLocation(location.pathname);
        }
        return <Navigate to="/loginv2" />;
    }

    if (requestedLocation && location.pathname !== requestedLocation) {
        setRequestLocation(null);
        return <Navigate to={requestedLocation} />;
    }

    if (userData?.role == "vendor") {
        return children;
    } else {
        return <Navigate to="/" />;
    }
}

export default VendorGuard;