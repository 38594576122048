import { createContext, useReducer, useContext } from "react";

const handlers = {
    SUBSCRIPTIONS: (state, action) => {
        const data = action.payload;

        return {
            ...state,
            data
        };
    },
}

const reducer = (state, action) =>
    handlers[action.type] ? handlers[action.type](state, action) : state;

const initialState = {
    isLoading: false,
    subscriptions: null,
};

export const CommonContext = createContext({
    ...initialState,
    getSubscriptions: () => Promise.resolve(),
});

export const CommonProvider = (props) => {
    const { children } = props;
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <CommonContext.Provider
            value={{
                ...state,
                dispatch,
            }}
        >
            {children}
        </CommonContext.Provider>
    );
};

const useCommonContext = () => useContext(CommonContext);

export default useCommonContext;

export const rj = async (data , dispatch) => {

    try {

      dispatch({
        type: "SUBSCRIPTIONS",
        payload: {
            rj1 : data,
        },
      });
    } catch (err) {
      dispatch({
        type: "SUBSCRIPTIONS",
        payload: {
            rj1: null,
        },
      });
    }
  };
