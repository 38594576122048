import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import logo from "../../assert/image/logoNew.png";
import { makeStyles } from "@material-ui/core/styles";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import Box from "@mui/material/Box";
import { Typography } from "@material-ui/core";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
// import { GoogleMap , useLoadScript , Marker } from "@react-google-maps/api";
// import { config } from "../config";
import "../../assert/css/navbar.css";
import dayjs from "dayjs";
import useUserContext, { userLanguage } from "../../contexts/userContext";
import home from "../../transalation/home.json";
import { useState } from "react";
import { useEffect } from "react";
import ProfilePopUp from "./MyProfile";
import { useNavigate } from "react-router-dom";
import VendorProfilePopUP from "../vendor/MyProfile";
import dummyProfileImg from "./assets/images/images.jpeg";
import evente from "../../assert/image/logo.png";
import moment from "moment";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PropTypes from "prop-types";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import LocationModal from "./LocationModal";
import { colors } from "@mui/material";

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  sideNav: {},
  mobileLinkList: {
    "& a": {
      textDecoration: "none",
      color: "#fff",
      fontFamily: "Inter",
    },
  },
  userCustomHeader: {
    "@media screen and (max-width:992px)": {
      width: "55%",
    },
    "@media screen and (max-width:480px)": {
      width: "60%",
    },
  },
  bgBlack: {
    background: "black",
  },
  image: {
    width: "100px",

    [theme.breakpoints.down("sm")]: {
      width: "78px",
      padding: "0px 0px",
      height: "auto",
    },
  },
  white: {
    color: "white",
  },
  marginAuto: {
    margin: "auto 0px",
  },
  d_flex: {
    display: "flex",
  },
  justifyCenter: {
    justifyContent: "center !Important",
  },
  justifyBetween: {
    justifyContent: "space-between",
  },
  justifyEvenly: {
    justifyContent: "space-evenly",
  },
  spaceBetween: {
    justifyContent: "space-between",
  },
  flex_wrap: {
    flexWrap: "wrap !Important",
  },
  ml5: {
    marginLeft: "5px",
  },
  ml10: {
    marginLeft: "10px",
  },
  ml20: {
    marginLeft: "20px",
  },
  ml40: {
    marginLeft: "40px",
  },
  width100: {
    width: "100%",
  },
  dropdown: {
    background: "transparent !Important",
    border: "none !Important",
    color: "white",
    outline: "none",
    fontFamily: "Inter !important",
    [theme.breakpoints.up("xs")]: {
      // color: "#008e7a",
      fontSize: "14px",
      fontWeight: "500",
    },
  },

  dropdown2: {
    left: "15px",
    width: "100%",
    background: "transparent",
    color: "black",
    border: "1px solid white",
    outline: "none",
  },
  button: {
    height: "40px",
    background: "#ffff",
    color: "#008E7A !Important",
    margin: "auto",
    borderRadius: "20px",
    border: "none",
    fontSize: "15px",
    fontFamily: "Mulish",
    fontWeight: "600",
    paddingLeft: "20px !Important",
    paddingRight: "20px !Important",
    marginLeft: "20px",
  },
  button2: {
    height: "40px",
    background: "rgba(0, 142, 122, 1)",
    color: "white",
    borderRadius: "20px",
    border: "none",
    fontSize: "15px",
    fontFamily: "Mulish",
    fontWeight: "600",
    paddingLeft: "20px !Important",
    paddingRight: "20px !Important",
    marginTop: "auto",
    marginBottom: "auto",
  },
  displayNone: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  displayBlock: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  p1: {
    fontSize: "17px",
    fontFamily: "Mulish !important",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
  },
  textWhite: {
    color: "#FFFFF !Important",
  },
  bgWhite: {
    background: "#008E7A",
    color: "#FFF",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  },
  profile: {
    background: "rgba(0, 142, 122, 1)",
    color: "white",
    margin: "auto",
    borderRadius: "5px",
    border: "none",
    fontSize: "28px",
    fontFamily: "Mulish",
    fontWeight: "700",
    width: "60px",
    height: "50px",
  },
  profileName: {
    margin: "auto",
    marginLeft: "10px",
    fontFamily: "Mulish",
    fontWeight: "700",
    fontSize: "18px",
    color: "rgba(0, 142, 122, 1)",
  },
  ptnav: {
    padding: " 2px 30px",
  },

  customDateTimePicker: {
    "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
      display: "none",
      margin: "auto !Important",
    },
    "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
      color: "white !Important",
      "font-size": "17px",
    },
    "& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root": {
      "flex-direction": "row-reverse",
      margin: "auto",
    },
    "& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root": {
      margin: "auto !important",
    },
  },
  hover: {
    "&:hover": {
      cursor: "pointer",
      opacity: "0.7",
    },
  },
  paddR: {
    paddingRight: " 9px",
  },
  alignItem: {
    alignItems: "center",
  },
  calenderr: {
    color: "white",
    height: "27px",
    width: "50px",
    backgroundColor: "transparent",
    border: "0px",
    fontSize: "17px",
  },
  calendermob: {
    color: "Black",
    height: "27px",
    width: "58px",
    backgroundColor: "transparent",
    border: "0px",
    fontSize: "17px",
    "@media screen and (max-width:992px)": {
      color: "#fff",
    },
  },
  borderL: {
    borderLeft: "1px solid rgba(219, 215, 215, 1)",
    borderRight: "1px solid rgba(219, 215, 215, 1)",
    paddingInline: " 8px",
    marginInline: " 8px",

    height: "23px",
    alignItems: "center",
  },
  dflex: {
    display: "flex",
    alignItems: "center",
  },
  inputspac: {
    color: "white",
    height: "30px",
    width: " 76px",
    backgroundColor: "transparent",
    border: "0px",
    fontSize: "17px",
    fontFamily: "Mulish !important",
  },
  inputspacmob: {
    color: "black",
    height: "30px",
    width: " 76px",
    backgroundColor: "transparent",
    border: "0px",
    fontSize: "17px",
    fontFamily: "Mulish !important",
    "@media screen and (max-width:992px)": {
      color: "#fff",
    },
  },
  svgh: {
    width: ".9em!important",
  },
  padl: {
    paddingLeft: "30px",
  },
  appBar: {
    background: "rgba(0, 142, 122, 1) !important",
    color: "white",
    boxShadow: "none !important",
    borderBottom: "1px solid rgba(255, 255, 255, 0.12)",
    position: "relative !important",
    fontFamily: "Mulish !important",
    "max-height": "95px",
  },
}));

function Navbar(props) {
  const classes = useStyles();
  let role = localStorage.getItem("EM_User");
  role = JSON.parse(role);
  if (role?.data) {
    role = role?.data;
  }
  const { language, dispatch } = useUserContext();
  const [content, setContent] = useState({});
  const [profileState, setProfileState] = useState(false);
  const [vendorprofileState, setVendorProfileState] = useState(false);
  const [locationModalState, setLocationModalState] = useState(false);
  const [locations, setLocation] = useState("");

  const [splitProfileName, setSplitProfileName] = useState("");
  const navigate = useNavigate();

  const [age, setAge] = React.useState("Langauge");

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const langHandleChange = (event) => {
    setAge(event.target.value);
  };

  useEffect(() => {
    if (locations) {
      localStorage.setItem("EM_LOCATION", locations);
    } else {
      let loc = localStorage.getItem("EM_LOCATION");
      setLocation(loc || "");
    }
  }, [locations]);

  useEffect(() => {
    if (language === "english") {
      setContent(home.english);
    } else {
      setContent(home.arabic);
    }
  }, [language]);

  useEffect(() => {
    if (role?.role !== "user") {
      if (role?.name?.split(" ").length > 1)
        setSplitProfileName(
          role?.name?.split(" ")?.[0]?.slice(0, 1).toUpperCase() +
            role?.name?.split(" ")?.[1]?.slice(0, 1).toUpperCase()
        );
      else setSplitProfileName(role?.name?.slice(0, 1).toUpperCase());
    } else if (role?.role === "vendor") {
      if (role?.name?.split(" ").length > 1)
        setSplitProfileName(
          role?.firstname?.split(" ")?.[0]?.slice(0, 1).toUpperCase() +
            role?.lastname?.split(" ")?.[1]?.slice(0, 1).toUpperCase()
        );
      else setSplitProfileName(role?.firstname?.slice(0, 1).toUpperCase());
    }
  }, [role?.name]);

  useEffect(() => {
    let name = "";
    if (role?.role === "user") {
      name += role?.firstname[0].toUpperCase();
      if (role?.lastname) name += role?.lastname[0].toUpperCase();
      setSplitProfileName(name);
    }
  }, [role?.firstname]);

  useEffect(() => {
    let name = "";
    if (role?.role === "vendor") {
      name += role?.firstname[0].toUpperCase();
      if (role?.lastname) name += role?.lastname[0].toUpperCase();
      setSplitProfileName(name);
    }
  }, [role?.firstname]);

  const logout = () => {
    setProfileState(!profileState);
    localStorage.removeItem("EM_User");
    localStorage.removeItem("EM_TOKEN");
    localStorage.removeItem("EM_LOCATION");
    navigate("/");
  };

  const logoutVendor = () => {
    setVendorProfileState(!vendorprofileState);
    localStorage.removeItem("EM_User");
    localStorage.removeItem("EM_TOKEN");
    localStorage.removeItem("EM_LOCATION");
    navigate("/");
  };

  const profileStateHandler = () => {
    setProfileState(!profileState);
  };

  const vendorprofileStateHandler = () => {
    console.log("vendorprofileStateHandler called");
    setVendorProfileState((prevState) => !prevState);
  };

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  function handleLocationPopup() {
    setLocationModalState(!locationModalState);
  }

  const mobileMenuId = "primary-search-account-menu-mobile";

  return (
    <>
      <LocationModal
        locationModalState={locationModalState}
        handleLocationPopup={handleLocationPopup}
        setLocation2={setLocation}
      />
      <AppBar component="nav" className={classes.appBar}>
        <Toolbar
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Box
            className={`${classes.userCustomHeader} d-flex align-items-center justify-content-between`}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ display: { md: "none !Important" } }}
            >
              <MenuIcon />
            </IconButton>
            <Link to={"/"}>
              {" "}
              <img
                src={logo}
                alt=""
                className={`${classes.image} ${classes.hover}`}
                width={70}
                height={100}
              />
            </Link>
          </Box>
          <Box
            className="flex-grow-1 d-flex align-items-center justify-content-lg-between justify-content-end  column-gap-3"
            sx={{
              marginLeft: { lg: "80px", md: "20px", sm: "20px", xs: "20px" },
              marginRight: { lg: "24px", md: "20px", sm: "18px", xs: "16px" },
            }}
          >
            <Box
              className={` ${classes.d_flex} ${classes.alignItem}`}
              sx={{ display: { xs: "none !Important", md: "flex !Important" } }}
            >
              <Box className={` ${classes.dflex}`}>
                <Button
                  className={`${classes.hover} d-flex align-items-center gap-1 px-0`}
                  sx={{
                    color: "white",
                    minWidth: "fit-content",
                    fontFamily: "Mulish !important",
                    fontWeight: "600 !important",
                    fontSize: "16px !important",
                  }}
                  onClick={handleLocationPopup}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 10.4782C15 11.2721 14.6839 12.0336 14.1213 12.5951C13.5587 13.1565 12.7956 13.4719 12 13.4719C11.2044 13.4719 10.4413 13.1565 9.87868 12.5951C9.31607 12.0336 9 11.2721 9 10.4782C9 9.68415 9.31607 8.92267 9.87868 8.36123C10.4413 7.79979 11.2044 7.48438 12 7.48438C12.7956 7.48438 13.5587 7.79979 14.1213 8.36123C14.6839 8.92267 15 9.68415 15 10.4782Z"
                      stroke="white"
                      stroke-width="1.27756"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M19.5 10.4786C19.5 17.6058 12 21.7052 12 21.7052C12 21.7052 4.5 17.6058 4.5 10.4786C4.5 8.49358 5.29018 6.58989 6.6967 5.18628C8.10322 3.78268 10.0109 2.99414 12 2.99414C13.9891 2.99414 15.8968 3.78268 17.3033 5.18628C18.7098 6.58989 19.5 8.49358 19.5 10.4786Z"
                      stroke="white"
                      stroke-width="1.27756"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                  <Typography
                    variant="span"
                    style={{
                      fontFamily: "Mulish !important",
                      color: "white !important",
                    }}
                  >
                    {locations}
                  </Typography>
                </Button>
                <Box
                  className={` ${classes.borderL} ${classes.dflex} align-items-center gap-1`}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.75 1V3.25M15.25 1V3.25M1 16.75V5.5C1 4.90326 1.23705 4.33097 1.65901 3.90901C2.08097 3.48705 2.65326 3.25 3.25 3.25H16.75C17.3467 3.25 17.919 3.48705 18.341 3.90901C18.7629 4.33097 19 4.90326 19 5.5V16.75M1 16.75C1 17.3467 1.23705 17.919 1.65901 18.341C2.08097 18.7629 2.65326 19 3.25 19H16.75C17.3467 19 17.919 18.7629 18.341 18.341C18.7629 17.919 19 17.3467 19 16.75M1 16.75V9.25C1 8.65326 1.23705 8.08097 1.65901 7.65901C2.08097 7.23705 2.65326 7 3.25 7H16.75C17.3467 7 17.919 7.23705 18.341 7.65901C18.7629 8.08097 19 8.65326 19 9.25V16.75"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                  <input
                    className={`${classes.customDateTimePicker} ${classes.calenderr} `}
                    value={moment(new Date()).format("DD/MM")}
                    disabled
                  />
                </Box>
                <Box className={`${classes.dflex} align-items-center gap-1`}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 4V10H14.5M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10Z"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                  <input
                    className={`${classes.customDateTimePicker} ${classes.inputspac}`}
                    value={moment(new Date()).format("HH:mm")}
                    disabled
                  />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: "none !Important", md: "flex !Important" },
                gap: { lg: "28px", md: "20px", sm: "16px", xs: "16px" },
              }}
            >
              <Typography className={`${classes.p1} ${classes.hover}`}>
                <Link
                  to={"/?section=create"}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {content?.create}{" "}
                </Link>
              </Typography>
              <Typography className={`${classes.p1} ${classes.hover}`}>
                <Link
                  to="/automate_event"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {content?.automate}
                </Link>
              </Typography>
              <Typography className={`${classes.p1} ${classes.hover}`}>
                <Link
                  to="/?section=vendor"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {content?.vendors}
                </Link>
              </Typography>
            </Box>
            <Box
              className="d-flex align-items-center justify-content-between "
              sx={{ gap: { lg: "28px", md: "20px", sm: "16px", xs: "16px" } }}
            >
              <Box
                sx={{
                  display: { xs: "none !Important", md: "flex !Important" },
                }}
              >
                {role?.role === "user" ? (
                  " "
                ) : (
                  <Typography className={`${classes.p1} ${classes.hover} lh-1`}>
                    <Link
                      to={"/signup-vendor-details"}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      {content?.becomeAvendor}
                    </Link>
                  </Typography>
                )}
              </Box>
              <Box
                sx={{
                  display: { xs: "none !Important", sm: "flex !Important" },
                  fontFamily: "Mulish",
                }}
              >
                <select
                  name=""
                  id=""
                  className={`${classes.dropdown}  ${classes.p1} ${classes.hover} ${classes.textWhite}`}
                  value={language}
                  onChange={(e) => userLanguage(e.target.value, dispatch)}
                >
                  <option
                    value="english"
                    style={{ color: "black", textAlign: "center" }}
                  >
                    English
                  </option>
                  <option
                    value="arabic"
                    style={{ color: "black", textAlign: "center" }}
                  >
                    عربي
                  </option>
                </select>
              </Box>
            </Box>
          </Box>
          <Box
            className={`${classes.d_flex} `}
            sx={{ display: { xs: "none !Important", sm: "flex !Important" } }}
          >
            {role?.role === "user" ? (
              <Box className={classes.d_flex}>
                {role?.profileImageUrl ? (
                  <img
                    src={role?.profileImageUrl}
                    width="50"
                    className={`${classes.profile} ${classes.hover}`}
                    onClick={() => {
                      profileStateHandler();
                    }}
                    alt=""
                  />
                ) : (
                  <Typography
                    className={`${classes.profile} ${classes.hover}`}
                    onClick={() => {
                      profileStateHandler();
                    }}
                  >
                    {splitProfileName}
                  </Typography>
                )}
                {/* <Typography className={`${classes.profileName}`}>{splitProfileName}</Typography> */}
              </Box>
            ) : role?.role === "vendor" ? (
              <Box className={classes.d_flex}>
                {role?.profileImageUrl ? (
                  <img
                    src={role?.profileImageUrl}
                    width="50"
                    className={`${classes.profile} ${classes.hover}`}
                    onClick={vendorprofileStateHandler}
                    alt="profile"
                  />
                ) : (
                  <Typography
                    className={`${classes.profile} ${classes.hover}`}
                    onClick={vendorprofileStateHandler}
                  >
                    {splitProfileName}
                  </Typography>
                )}
                {/* <Typography className={`${classes.profileName}`}>{splitProfileName}</Typography> */}
              </Box>
            ) : (
              <button className={`${classes.button} ms-0`}>
                <Link
                  to={"/loginv2"}
                  style={{ textDecoration: "none", color: "#008E7A" }}
                >
                  {content?.loginNSignup}
                </Link>
              </button>
            )}
          </Box>
          <Box
            className={`${classes.d_flex} `}
            sx={{ display: { xs: "flex !Important", sm: "none !Important" } }}
          >
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block !Important", md: "none !Important" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              height: "100%",
              minHeight: "100vh",
              background: "#008e7a",
              paddingLeft: "10px",
            },
          }}
        >
          <Box
            onClick={handleDrawerToggle}
            sx={{ textAlign: "center" }}
            className={classes.sideNav}
          >
            <List className={classes.mobileLinkList}>
              <ListItem disablePadding>
                <Box
                  component={"div"}
                  className="w-100 d-flex flex-row align-items-center my-3"
                >
                  <Box className={` ${classes.dflex}`}>
                    <Button
                      className={`  ${classes.hover} gap-1 align-items-center`}
                      onClick={handleLocationPopup}
                      sx={{
                        color: "white",
                        minWidth: "fit-content",
                        fontFamily: "Mulish !important",
                        fontWeight: "600 !important",
                        fontSize: "14px !important",
                      }}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15 10.4782C15 11.2721 14.6839 12.0336 14.1213 12.5951C13.5587 13.1565 12.7956 13.4719 12 13.4719C11.2044 13.4719 10.4413 13.1565 9.87868 12.5951C9.31607 12.0336 9 11.2721 9 10.4782C9 9.68415 9.31607 8.92267 9.87868 8.36123C10.4413 7.79979 11.2044 7.48438 12 7.48438C12.7956 7.48438 13.5587 7.79979 14.1213 8.36123C14.6839 8.92267 15 9.68415 15 10.4782Z"
                          stroke="white"
                          stroke-width="1.27756"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M19.5 10.4786C19.5 17.6058 12 21.7052 12 21.7052C12 21.7052 4.5 17.6058 4.5 10.4786C4.5 8.49358 5.29018 6.58989 6.6967 5.18628C8.10322 3.78268 10.0109 2.99414 12 2.99414C13.9891 2.99414 15.8968 3.78268 17.3033 5.18628C18.7098 6.58989 19.5 8.49358 19.5 10.4786Z"
                          stroke="white"
                          stroke-width="1.27756"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>

                      <Typography
                        variant="span"
                        style={{
                          width: "30px",
                          "text-overflow": "ellipsis",
                          "white-space": "nowrap",
                          overflow: "hidden",
                          fontFamily: "Mulish !important",
                        }}
                      >
                        {locations}
                      </Typography>
                    </Button>
                  </Box>
                  <Box
                    className={` ${classes.borderL} ${classes.dflex} gap-1 align-items-center`}
                    sx={{ minWidth: "fit-content !important", color: "#fff" }}
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.75 1V3.25M15.25 1V3.25M1 16.75V5.5C1 4.90326 1.23705 4.33097 1.65901 3.90901C2.08097 3.48705 2.65326 3.25 3.25 3.25H16.75C17.3467 3.25 17.919 3.48705 18.341 3.90901C18.7629 4.33097 19 4.90326 19 5.5V16.75M1 16.75C1 17.3467 1.23705 17.919 1.65901 18.341C2.08097 18.7629 2.65326 19 3.25 19H16.75C17.3467 19 17.919 18.7629 18.341 18.341C18.7629 17.919 19 17.3467 19 16.75M1 16.75V9.25C1 8.65326 1.23705 8.08097 1.65901 7.65901C2.08097 7.23705 2.65326 7 3.25 7H16.75C17.3467 7 17.919 7.23705 18.341 7.65901C18.7629 8.08097 19 8.65326 19 9.25V16.75"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    <input
                      className={`${classes.customDateTimePicker} ${classes.calendermob} `}
                      value={moment(new Date()).format("DD/MM")}
                      disabled
                    />
                  </Box>
                  <Box
                    className={`  ${classes.dflex} gap-1 align-items-center`}
                    sx={{ minWidth: "fit-content !important", color: "#fff" }}
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 4V10H14.5M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    <input
                      className={`${classes.customDateTimePicker} ${classes.inputspacmob}`}
                      value={moment(new Date()).format("HH:mm")}
                      disabled
                    />
                  </Box>
                </Box>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton sx={{ textAlign: "center" }}>
                  {/* <Box> */}
                  <Typography
                    className={` ${classes.marginAuto} ${classes.textWhite} ${classes.p1} ${classes.hover}`}
                  >
                    <Link to={"/?section=create"}>{content?.create} </Link>
                  </Typography>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton sx={{ textAlign: "center" }}>
                  <Typography
                    className={` ${classes.marginAuto}${classes.p1} ${classes.hover}`}
                  >
                    <Link
                      to="/automate_event"
                      // style={{ textDecoration: "none", color: "white" }}
                    >
                      {content?.automate}
                    </Link>
                  </Typography>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton>
                  <Typography className={`${classes.hover}`}>
                    <Link to="/?section=vendor">{content?.vendors}</Link>
                  </Typography>
                </ListItemButton>
              </ListItem>
              {role?.role === "user" ? (
                ""
              ) : (
                <ListItem disablePadding>
                  <ListItemButton>
                    <Typography className={`${classes.hover}`}>
                      <Link to={"/signup-vendor-details"}>
                        {content?.becomeAvendor}
                      </Link>
                    </Typography>
                  </ListItemButton>
                </ListItem>
              )}
            </List>
          </Box>
        </Drawer>
      </nav>

      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <MenuItem className="d-flex justify-content-end">
          <select
            name=""
            id=""
            className={`${classes.dropdown} ${classes.hover}`}
            style={{ color: "rgb(0, 142, 122)" }}
            value={language}
            onChange={(e) => userLanguage(e.target.value, dispatch)}
          >
            <option value="english">English</option>
            <option value="arabic">عربي</option>
          </select>
        </MenuItem>
        <MenuItem>
          <Box className={`${classes.d_flex} `}>
            {role?.role === "user" ? (
              <Box className={classes.d_flex}>
                {role?.profileImageUrl ? (
                  <img
                    src={role?.profileImageUrl}
                    width="50"
                    className={`${classes.profile} ${classes.hover}`}
                    onClick={() => {
                      profileStateHandler();
                    }}
                  />
                ) : (
                  <Typography
                    className={`${classes.profile} ${classes.hover}`}
                    onClick={() => {
                      profileStateHandler();
                    }}
                  >
                    {splitProfileName}
                  </Typography>
                )}
                {/* <Typography className={`${classes.profileName}`}>{splitProfileName}</Typography> */}
              </Box>
            ) : role?.role === "vendor" ? (
              <Box className={classes.d_flex}>
                {role?.profileImageUrl ? (
                  <img
                    src={role?.profileImageUrl}
                    width="50"
                    className={`${classes.profile} ${classes.hover}`}
                    onClick={vendorprofileStateHandler}
                  />
                ) : (
                  <Typography
                    className={`${classes.profile} ${classes.hover}`}
                    onClick={vendorprofileStateHandler}
                  >
                    {splitProfileName}
                  </Typography>
                )}
                {/* <Typography className={`${classes.profileName}`}>{splitProfileName}</Typography> */}
              </Box>
            ) : (
              <Button
                className={`${classes.button} ms-0 py-0`}
                sx={{ paddingInline: "0 !important" }}
              >
                <Link
                  to={"/loginv2"}
                  style={{ textDecoration: "none", color: "#008E7A" }}
                >
                  {content?.loginNSignup}
                </Link>
              </Button>
            )}
          </Box>
        </MenuItem>
      </Menu>

      {/* <GoogleMap zoom={10} center={{lat : 44 , lng : -80}} style={{width : "100%" , height : "100vh"}}>
        <Marker
        position={{lat : 44 , lng : -80}}/>
      </GoogleMap> */}
      {profileState ? (
        <ProfilePopUp
          name={splitProfileName}
          user={role}
          logout={logout}
          profileStateHandler={profileStateHandler}
          profileState={profileState}
        />
      ) : (
        <></>
      )}

      {vendorprofileState ? (
        <VendorProfilePopUP
          name={splitProfileName}
          user={role}
          logout={logoutVendor}
          vendorprofileStateHandler={vendorprofileStateHandler}
          vendorprofileState={vendorprofileState}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default Navbar;
