// reducer.js

import { ADD_BANK_DETAILS } from '../actions/actiontypes';

const initialState = {
  bankDetails: [],
};

const bankDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_BANK_DETAILS:
      return {
        ...state,
        bankDetails: action.payload,
      };
    default:
      return state;
  }
};

export default bankDetailsReducer;
