import { Box } from '@mui/material'
import React from 'react'
import Logo from '../../../assert/image/logo.png'

const LeftLogo = () => {
  return (
    <Box className='col-xxl-5 col-sm-4 col-12'>
        <Box className='left'>
            <Box className="img-box">
                <img src={Logo} alt='Logo' />
            </Box>
        </Box>
    </Box>

  )
}

export default LeftLogo
