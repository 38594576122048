import {
  FETCH_ALL_PAYMENTS_REQUEST,
  FETCH_ALL_PAYMENTS_SUCCESS,
  FETCH_ALL_PAYMENTS_FAILURE,
  FETCH_COMPLETED_PAYMENTS_REQUEST,
  FETCH_COMPLETED_PAYMENTS_SUCCESS,
  FETCH_COMPLETED_PAYMENTS_FAILURE,
  FETCH_CANCELLED_PAYMENTS_REQUEST,
  FETCH_CANCELLED_PAYMENTS_SUCCESS,
  FETCH_CANCELLED_PAYMENTS_FAILURE,
  FETCH_UPCOMING_PAYMENTS_REQUEST,
  FETCH_UPCOMING_PAYMENTS_SUCCESS,
  FETCH_UPCOMING_PAYMENTS_FAILURE,
} from "../actions/actiontypes";

const initialState = {
  allPayments: [],
  reciveidPayments: [],
  refundedPayments: [],
  pendingPayments: [],
  loading: false,
  error: null,
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    // Reducers for fetching all reports
    case FETCH_ALL_PAYMENTS_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_ALL_PAYMENTS_SUCCESS:
      return { ...state, loading: false, allPayments: action.payload };
    case FETCH_ALL_PAYMENTS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    // Reducers for fetching completed reports
    case FETCH_COMPLETED_PAYMENTS_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_COMPLETED_PAYMENTS_SUCCESS:
      return { ...state, loading: false, reciveidPayments: action.payload };
    case FETCH_COMPLETED_PAYMENTS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    // Reducers for fetching cancelled reports
    case FETCH_CANCELLED_PAYMENTS_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_CANCELLED_PAYMENTS_SUCCESS:
      return { ...state, loading: false, refundedPayments: action.payload };
    case FETCH_CANCELLED_PAYMENTS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    // Reducers for fetching upcoming reports
    case FETCH_UPCOMING_PAYMENTS_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_UPCOMING_PAYMENTS_SUCCESS:
      return { ...state, loading: false, pendingPayments: action.payload };
    case FETCH_UPCOMING_PAYMENTS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default orderReducer;
