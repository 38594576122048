// bankActions.js

import { ADD_BANK_DETAILS } from './actiontypes'; // Assuming you have defined action types
import axios from 'axios';

export const addBankDetails = (bankDetails, token) => {
    const config = {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
        }
    };
    return async (dispatch) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_WEB_URL}/vendor/signup/bank-detail`, bankDetails, config);
            dispatch({
                type: ADD_BANK_DETAILS,
                payload: response.data, // Assuming response.data contains the updated bank details
            });
            return response.data; // Return response data upon success
        } catch (error) {
            console.error('Error adding bank details:', error);
            throw error; // Throw the error to be handled outside of this function
        }
    };
};

export const addContactDetails = (bankDetails, token) => {
    const config = {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
        }
    };
    return async (dispatch) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_WEB_URL}/vendor/signup/contact-detail`, bankDetails, config);
            dispatch({
                type: ADD_BANK_DETAILS,
                payload: response.data, // Assuming response.data contains the updated bank details
            });
            return response.data; // Return response data upon success

        } catch (error) {
            console.error('Error adding Contact details:', error);
            throw error; // Throw the error to be handled outside of this function
        }
    };
};