// In your Redux reducer file (e.g., forgotPasswordReducer.js)

import { FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_FAILURE } from '../actions/actiontypes';

const initialState = {
    loading: false,
    error: null,
};

const forgotPasswordReducer = (state = initialState, action) => {
    switch (action.type) {
        case FORGOT_PASSWORD_REQUEST:
            return { ...state, loading: true, error: null };
        case FORGOT_PASSWORD_SUCCESS:
            return { ...state, loading: false };
        case FORGOT_PASSWORD_FAILURE:
            return { ...state, loading: false, error: action.error };
        default:
            return state;
    }
};

export default forgotPasswordReducer;
