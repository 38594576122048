import React from "react";
import PropTypes from "prop-types";
import "./ErrorMessage.scss";

const ErrorMessage = ({ message, className }) => {
  return message ? <p className={`error-message ${className}`}>{message}</p> : null;
};

ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
  className: PropTypes.string, 
  };

ErrorMessage.defaultProps = {
  className: "",
  
};

export default ErrorMessage;
