export const ActionTypes = {
  ADD_COMPANY_BASIC_DETAILS: "ADD_COMPANY_BASIC_DETAILS",
  ADD_VENDOR_BASIC_DETAILS: "ADD_VENDOR_BASIC_DETAILS",
  EMPTY_VENDOR_BASIC_DETAILS: "EMPTY_VENDOR_BASIC_DETAILS",
  ADD_COMPANY_BANK_DETAILS: "ADD_COMPANY_BANK_DETAILS",
};
export const ADD_BANK_DETAILS = "ADD_BANK_DETAILS";
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const VERIFY_OTP_REQUEST = "VERIFY_OTP_REQUEST";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAILURE = "VERIFY_OTP_FAILURE";

export const RESEND_OTP_REQUEST = "RESEND_OTP_REQUEST";
export const RESEND_OTP_SUCCESS = "RESEND_OTP_SUCCESS";
export const RESEND_OTP_FAILURE = "RESEND_OTP_FAILURE";

export const DELETE_ITEM_REQUEST = "DELETE_ITEM_REQUEST";
export const DELETE_ITEM_SUCCESS = "DELETE_ITEM_SUCCESS";
export const DELETE_ITEM_FAILURE = "DELETE_ITEM_FAILURE";

export const PASSWORD_REQUEST = "PASSWORD_REQUEST";
export const PASSWORD_SUCCESS = "PASSWORD_SUCCESS";
export const PASSWORD_FAILURE = "PASSWORD_FAILURE";

// actionTypes.js
export const FETCH_ALL_ORDERS_REQUEST = "FETCH_ALL_ORDERS_REQUEST";
export const FETCH_ALL_ORDERS_SUCCESS = "FETCH_ALL_ORDERS_SUCCESS";
export const FETCH_ALL_ORDERS_FAILURE = "FETCH_ALL_ORDERS_FAILURE";

export const FETCH_COMPLETED_ORDERS_REQUEST = "FETCH_COMPLETED_ORDERS_REQUEST";
export const FETCH_COMPLETED_ORDERS_SUCCESS = "FETCH_COMPLETED_ORDERS_SUCCESS";
export const FETCH_COMPLETED_ORDERS_FAILURE = "FETCH_COMPLETED_ORDERS_FAILURE";

export const FETCH_CANCELLED_ORDERS_REQUEST = "FETCH_CANCELLED_ORDERS_REQUEST";
export const FETCH_CANCELLED_ORDERS_SUCCESS = "FETCH_CANCELLED_ORDERS_SUCCESS";
export const FETCH_CANCELLED_ORDERS_FAILURE = "FETCH_CANCELLED_ORDERS_FAILURE";

export const FETCH_UPCOMING_ORDERS_REQUEST = "FETCH_UPCOMING_ORDERS_REQUEST";
export const FETCH_UPCOMING_ORDERS_SUCCESS = "FETCH_UPCOMING_ORDERS_SUCCESS";
export const FETCH_UPCOMING_ORDERS_FAILURE = "FETCH_UPCOMING_ORDERS_FAILURE";

export const FETCH_VIEW_ORDER_REQUEST = "FETCH_VIEW_ORDER_REQUEST";
export const FETCH_VIEW_ORDER_SUCCESS = "FETCH_VIEW_ORDER_SUCCESS";
export const FETCH_VIEW_ORDER_FAILURE = "FETCH_VIEW_ORDER_FAILURE";

export const CANCEL_ORDER_REQUEST = "CANCEL_ORDER_REQUEST";
export const CANCEL_ORDER_SUCCESS = "CANCEL_ORDER_SUCCESS";
export const CANCEL_ORDER_FAILURE = "CANCEL_ORDER_FAILURE";

export const UPLOAD_BANNER_IMAGE_REQUEST = "UPLOAD_BANNER_IMAGE_REQUEST";
export const UPLOAD_BANNER_IMAGE_SUCCESS = "UPLOAD_BANNER_IMAGE_SUCCESS";
export const UPLOAD_BANNER_IMAGE_FAILURE = "UPLOAD_BANNER_IMAGE_FAILURE";

export const FETCH_SECTION_DETAILS_REQUEST = "FETCH_SECTION_DETAILS_REQUEST";
export const FETCH_SECTION_DETAILS_SUCCESS = "FETCH_SECTION_DETAILS_SUCCESS";
export const FETCH_SECTION_DETAILS_FAILURE = "FETCH_SECTION_DETAILS_FAILURE";

export const ADD_SECTION_REQUEST = "ADD_SECTION_REQUEST";
export const ADD_SECTION_SUCCESS = "ADD_SECTION_SUCCESS";
export const ADD_SECTION_FAILURE = "ADD_SECTION_FAILURE";

export const DELETE_SECTION_REQUEST = "DELETE_SECTION_REQUEST";
export const DELETE_SECTION_SUCCESS = "DELETE_SECTION_SUCCESS";
export const DELETE_SECTION_FAILURE = "DELETE_SECTION_FAILURE";

export const UPDATE_SECTION_REQUEST = "UPDATE_SECTION_REQUEST";
export const UPDATE_SECTION_SUCCESS = "UPDATE_SECTION_SUCCESS";
export const UPDATE_SECTION_FAILURE = "UPDATE_SECTION_FAILURE";

export const ADD_PRODUCT_ITEM_SUCCESS = "ADD_PRODUCT_ITEM_SUCCESS";
export const ADD_PRODUCT_ITEM_FAILURE = "ADD_PRODUCT_ITEM_FAILURE";

export const FETCH_PRODUCT_BY_ID_SUCCESS = "FETCH_PRODUCT_BY_ID_SUCCESS";
export const FETCH_PRODUCT_BY_ID_FAILURE = "FETCH_PRODUCT_BY_ID_FAILURE";

export const FETCH_EVENT_TYPES_SUCCESS = "FETCH_EVENT_TYPES_SUCCESS";
export const FETCH_EVENT_TYPES_FAILURE = "FETCH_EVENT_TYPES_FAILURE";

export const UPDATE_PRODUCT_ITEM_SUCCESS = "UPDATE_PRODUCT_ITEM_SUCCESS";
export const UPDATE_PRODUCT_ITEM_FAILURE = "UPDATE_PRODUCT_ITEM_FAILURE";

// actionTypes.js

export const FETCH_COMPANY_DETAILS_REQUEST = "FETCH_COMPANY_DETAILS_REQUEST";
export const FETCH_COMPANY_DETAILS_SUCCESS = "FETCH_COMPANY_DETAILS_SUCCESS";
export const FETCH_COMPANY_DETAILS_FAILURE = "FETCH_COMPANY_DETAILS_FAILURE";

export const FETCH_CONTACT_DETAILS_REQUEST = "FETCH_CONTACT_DETAILS_REQUEST";
export const FETCH_CONTACT_DETAILS_SUCCESS = "FETCH_CONTACT_DETAILS_SUCCESS";
export const FETCH_CONTACT_DETAILS_FAILURE = "FETCH_CONTACT_DETAILS_FAILURE";

export const ADD_ADDON_SECTION = "ADD_ADDON_SECTION";
export const EDIT_ADDON_SECTION = "EDIT_ADDON_SECTION";
export const CLEAR_ADDON_SECTION = "CLEAR_ADDON_SECTION";

export const ADDON_SECTION_SUCCESS = "ADDON_SECTION_SUCCESS";
export const ADDON_SECTION_FAILURE = "ADDON_SECTION_FAILURE";
export const FETCH_ADDON_SECTION_BY_ID_SUCCESS =
  "FETCH_ADDON_SECTION_BY_ID_SUCCESS";
export const FETCH_ADDON_SECTION_BY_ID_FAILURE =
  "FETCH_ADDON_SECTION_BY_ID_FAILURE";

export const DELETE_ADDON_SECTION_SUCCESS = "DELETE_ADDON_SECTION_SUCCESS";
export const DELETE_ADDON_SECTION_FAILURE = "DELETE_ADDON_SECTION_FAILURE";

export const FETCH_ALL_REPORTS_REQUEST = "FETCH_ALL_REPORTS_REQUEST";
export const FETCH_ALL_REPORTS_SUCCESS = "FETCH_ALL_REPORTS_SUCCESS";
export const FETCH_ALL_REPORTS_FAILURE = "FETCH_ALL_REPORTS_FAILURE";

export const FETCH_COMPLETED_REPORTS_REQUEST =
  "FETCH_COMPLETED_REPORTS_REQUEST";
export const FETCH_COMPLETED_REPORTS_SUCCESS =
  "FETCH_COMPLETED_REPORTS_SUCCESS";
export const FETCH_COMPLETED_REPORTS_FAILURE =
  "FETCH_COMPLETED_REPORTS_FAILURE";

export const FETCH_CANCELLED_REPORTS_REQUEST =
  "FETCH_CANCELLED_REPORTS_REQUEST";
export const FETCH_CANCELLED_REPORTS_SUCCESS =
  "FETCH_CANCELLED_REPORTS_SUCCESS";
export const FETCH_CANCELLED_REPORTS_FAILURE =
  "FETCH_CANCELLED_REPORTS_FAILURE";

export const FETCH_UPCOMING_REPORTS_REQUEST = "FETCH_UPCOMING_REPORTS_REQUEST";
export const FETCH_UPCOMING_REPORTS_SUCCESS = "FETCH_UPCOMING_REPORTS_SUCCESS";
export const FETCH_UPCOMING_REPORTS_FAILURE = "FETCH_UPCOMING_REPORTS_FAILURE";

export const FETCH_BANK_DETAILS_REQUEST = "FETCH_BANK_DETAILS_REQUEST";
export const FETCH_BANK_DETAILS_SUCCESS = "FETCH_BANK_DETAILS_SUCCESS";
export const FETCH_BANK_DETAILS_FAILURE = "FETCH_BANK_DETAILS_FAILURE";

export const DOWNLOAD_ITEM_REPORT_REQUEST = "DOWNLOAD_ITEM_REPORT_REQUEST";
export const DOWNLOAD_ITEM_REPORT_SUCCESS = "DOWNLOAD_ITEM_REPORT_SUCCESS";
export const DOWNLOAD_ITEM_REPORT_FAILURE = "DOWNLOAD_ITEM_REPORT_FAILURE";

export const DOWNLOAD_FEEDBACK_REPORT_REQUEST =
  "DOWNLOAD_FEEDBACK_REPORT_REQUEST";
export const DOWNLOAD_FEEDBACK_REPORT_SUCCESS =
  "DOWNLOAD_FEEDBACK_REPORT_SUCCESS";
export const DOWNLOAD_FEEDBACK_REPORT_FAILURE =
  "DOWNLOAD_FEEDBACK_REPORT_FAILURE";

export const FETCH_INVENTORY_REPORTS_REQUEST =
  "FETCH_INVENTORY_REPORTS_REQUEST";
export const FETCH_INVENTORY_REPORTS_SUCCESS =
  "FETCH_INVENTORY_REPORTS_SUCCESS";
export const FETCH_INVENTORY_REPORTS_FAILURE =
  "FETCH_INVENTORY_REPORTS_FAILURE";

export const UPDATE_ID = "UPDATE_ID";

// actionTypes.js
export const FETCH_FEEDBACK_REPORT_REQUEST = "FETCH_FEEDBACK_REPORT_REQUEST";
export const FETCH_FEEDBACK_REPORT_SUCCESS = "FETCH_FEEDBACK_REPORT_SUCCESS";
export const FETCH_FEEDBACK_REPORT_FAILURE = "FETCH_FEEDBACK_REPORT_FAILURE";

// ActionTypes.js

export const FETCH_VENDOR_PROFILE_REQUEST = "FETCH_VENDOR_PROFILE_REQUEST";
export const FETCH_VENDOR_PROFILE_SUCCESS = "FETCH_VENDOR_PROFILE_SUCCESS";
export const FETCH_VENDOR_PROFILE_FAILURE = "FETCH_VENDOR_PROFILE_FAILURE";
export const UPDATE_VENDOR_PROFILE_REQUEST = "UPDATE_VENDOR_PROFILE_REQUEST";
export const UPDATE_VENDOR_PROFILE_SUCCESS = "UPDATE_VENDOR_PROFILE_SUCCESS";
export const UPDATE_VENDOR_PROFILE_FAILURE = "UPDATE_VENDOR_PROFILE_FAILURE";

// actionTypes.js

export const FETCH_NOTIFICATIONS_REQUEST = "FETCH_NOTIFICATIONS_REQUEST";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_FAILURE = "FETCH_NOTIFICATIONS_FAILURE";

export const FETCH_ALL_PAYMENTS_REQUEST = "FETCH_ALL_PAYMENTS_REQUEST";
export const FETCH_ALL_PAYMENTS_SUCCESS = "FETCH_ALL_PAYMENTS_SUCCESS";
export const FETCH_ALL_PAYMENTS_FAILURE = "FETCH_ALL_PAYMENTS_FAILURE";

export const FETCH_COMPLETED_PAYMENTS_REQUEST =
  "FETCH_COMPLETED_PAYMENTS_REQUEST";
export const FETCH_COMPLETED_PAYMENTS_SUCCESS =
  "FETCH_COMPLETED_PAYMENTS_SUCCESS";
export const FETCH_COMPLETED_PAYMENTS_FAILURE =
  "FETCH_COMPLETED_PAYMENTS_FAILURE";

export const FETCH_CANCELLED_PAYMENTS_REQUEST =
  "FETCH_CANCELLED_PAYMENTS_REQUEST";
export const FETCH_CANCELLED_PAYMENTS_SUCCESS =
  "FETCH_CANCELLED_PAYMENTS_SUCCESS";
export const FETCH_CANCELLED_PAYMENTS_FAILURE =
  "FETCH_CANCELLED_PAYMENTS_FAILURE";

export const FETCH_UPCOMING_PAYMENTS_REQUEST =
  "FETCH_UPCOMING_PAYMENTS_REQUEST";
export const FETCH_UPCOMING_PAYMENTS_SUCCESS =
  "FETCH_UPCOMING_PAYMENTS_SUCCESS";
export const FETCH_UPCOMING_PAYMENTS_FAILURE =
  "FETCH_UPCOMING_PAYMENTS_FAILURE";
