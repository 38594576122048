const initialState = {
    cart: []
};

const userCartReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_CART":
            return {
                ...state
            };
        case "UPDATE_CART":
            state.cart = action.payload;
            return state;
        default:
            return state;
    }
};

export default userCartReducer;
