import './loader.scss'
import loaderImage from '../../../assert/image/loaderImage.png'
const LoaderComponent = () => {
  return (
    <div className="banter-loader">
    <div className="loader-container">
      <img src={loaderImage} alt="loaderImage" />
    </div>
  </div>
  )
}

export default LoaderComponent