import useUserContext from "../contexts/userContext";
import { Navigate } from "react-router-dom";

const SemiGaurd = ({ children }) => {

    
    const { data } = useUserContext();
    if (data?.otpEmail?.token !== undefined)
        return children
    return <Navigate to="/" />

}

export default SemiGaurd;