import { combineReducers } from "redux";
import { companyReducer } from "./companyReducer";
import bankDetailsReducer from "./bankDetailsReducer";
import forgetReducer from './forgetReducer';
import otpVerificationReducer from "./otpVerificationReducer";
import createPasswordReducer from "./createPasswordReducer";
import orderReducer from "./orderReducer";
import resendOtpReducer from "./resendOtpReducer";
import sectionReducer from "./sectionReducer"
import productReducer from "./productReducer"
import eventTypesReducer from "./eventTypesReducer"
import companyDetailsReducer from "./companyDetailsReducer";
import addonSectionsReducer from "./addonSectionsReducer";
import contactDetailReducer from './contactDetailsReducer';
import addonReducer from "./addonReducer";
import reportReducer from "./reportReducer";
import inventoryReducer from "./inventoryReducer";
import feedbackReducer from './feedbackReducer'
import feedback from "./feedbackByIdReducer";
import vendorProfileReducer from "./vendorProfileReducer"
import notificationReducer from "./notificationReducer"
import userCartReducer from "./userCartReducer"
import paymentReducer from './paymentReducer'

const reducers = combineReducers({
    companyReducer,
    bankDetailsReducer,
    forgetReducer,
    otpVerificationReducer,
    createPasswordReducer,
    order: orderReducer,
    report: reportReducer,
    inventory: inventoryReducer,
    resendOtpReducer,
    sectionReducer,
    productReducer,
    feedback,
    feedbackReducer,
    eventTypesReducer,
    companyDetailsReducer,
    vendorProfileReducer,
    addonReducer,
    payment:paymentReducer,
    notificationReducer,
    addonSectionsReducer,
    contactDetailReducer,
    userCartReducer,
})

export default reducers;