import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  pageHeading: {
    fontFamily: "Mulish",
    fontWeight: "800",
    fontSize: "24px",
    lineHeight: "30px",
    color: "rgba(0, 0, 0, 0.8)",
    letterSpacing: "0.01em",
    "@media (max-width: 992px)": {
      fontSize: "18px",
    },
    "@media (max-width: 767px)": {
      fontSize: "16px",
    },
  },
  accordionDesign: {
    "&:last-child .accordion-item": {
      borderBottom: "none !important",
    },
    "& .accordion-item": {
      borderBottom: "1px solid rgba(240, 241, 243, 1) !important",
    },
    "& .accordion-button": {
      fontSize: "18px",
      fontWeight: "700",
      fontFamily: "'Poppins', sans-serif",
      lineHeight: "1",
      color: "rgba(37, 37, 37, 0.7)",
      paddingBlock: "18px 22px",
      "@media (max-width: 992px)": {
        paddingBlock: "14px 16px",
        fontSize: "16px",
      },
      "@media (max-width: 767px)": {
        fontSize: "14px",
        padding: "12px 10px",
      },
      "&:focus": {
        boxShadow: "none",
      },
    },
    "& .accordion-button::after": {
      backgroundImage:
        "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='rgba(0, 142, 122, 1)' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M2 5L8 11L14 5'/%3E%3C/svg%3E\") !important",
      backgroundRepeat: "no-repeat",
    },
    "& .accordion-button:not(.collapsed)": {
      backgroundColor: "rgba(249, 249, 252, 1)",
      boxShadow: "none",
    },
    "& .accordion-collapse": {
      "&.show": {
        backgroundColor: "rgba(249, 249, 252, 1)",
      },
    },
    "& .accordion-body": {
      fontSize: "16px",
      fontWeight: "400",
      fontFamily: "'Poppins', sans-serif",
      lineHeight: "1.5",
      color: "rgba(37, 37, 37, 0.7)",
      "@media (max-width: 992px)": {
        paddingBlock: "14px 16px",
        fontSize: "14px",
      },
      "@media (max-width: 767px)": {
        fontSize: "12px",
        padding: "12px 10px",
      },
    },
  },
}));

export default useStyles;
