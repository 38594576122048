import { Navigate } from "react-router-dom";

export const GuestGuard = ({ children }) => {
    let token = localStorage.getItem("EM_User");
    token = JSON.parse(token);
    token = token?.token;

    if (token !== undefined) {
        return <Navigate to="/" />;
    }

    return children;
}

export const RootGuard = ({ children }) => {
    let token = localStorage.getItem("EM_User");
    token = JSON.parse(token);
    let userData = token?.data || {};
    token = token?.token;

    if (userData?.role === "vendor") {
        return <Navigate to="/vendor/dashboard" />;
    }

    return children;
}