// reducers.js
import {
  FETCH_FEEDBACK_REPORT_REQUEST,
  FETCH_FEEDBACK_REPORT_SUCCESS,
  FETCH_FEEDBACK_REPORT_FAILURE
} from '../actions/actiontypes';

const initialState = {
  loading: false,
  feedbackReport: [], // Initialize as an empty array
  error: null
};

const feedbackReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FEEDBACK_REPORT_REQUEST:
      console.log('Fetching feedback report...');
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_FEEDBACK_REPORT_SUCCESS:
      console.log('Feedback report fetched successfully:', action.payload);
      return {
        ...state,
        loading: false,
        feedbackReport: action.payload,
        error: null
      };
    case FETCH_FEEDBACK_REPORT_FAILURE:
      console.log('Failed to fetch feedback report:', action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload // Keep feedbackReport as an empty array
      };
    default:
      return state;
  }
};

export default feedbackReducer;
