// orderActions.js
import axios from 'axios';
import {
    FETCH_ALL_ORDERS_REQUEST, FETCH_ALL_ORDERS_SUCCESS, FETCH_ALL_ORDERS_FAILURE,
    FETCH_COMPLETED_ORDERS_REQUEST, FETCH_COMPLETED_ORDERS_SUCCESS, FETCH_COMPLETED_ORDERS_FAILURE,
    FETCH_CANCELLED_ORDERS_REQUEST, FETCH_CANCELLED_ORDERS_SUCCESS, FETCH_CANCELLED_ORDERS_FAILURE,
    FETCH_UPCOMING_ORDERS_REQUEST, FETCH_UPCOMING_ORDERS_SUCCESS, FETCH_UPCOMING_ORDERS_FAILURE, FETCH_VIEW_ORDER_REQUEST, FETCH_VIEW_ORDER_SUCCESS, FETCH_VIEW_ORDER_FAILURE, CANCEL_ORDER_REQUEST, CANCEL_ORDER_SUCCESS, CANCEL_ORDER_FAILURE
} from './actiontypes';


export const fetchAllOrdersRequest = () => ({ type: FETCH_ALL_ORDERS_REQUEST });
export const fetchAllOrdersSuccess = (orders) => ({ type: FETCH_ALL_ORDERS_SUCCESS, payload: orders });
export const fetchAllOrdersFailure = (error) => ({ type: FETCH_ALL_ORDERS_FAILURE, payload: error });

export const fetchCompletedOrdersRequest = () => ({ type: FETCH_COMPLETED_ORDERS_REQUEST });
export const fetchCompletedOrdersSuccess = (orders) => ({ type: FETCH_COMPLETED_ORDERS_SUCCESS, payload: orders });
export const fetchCompletedOrdersFailure = (error) => ({ type: FETCH_COMPLETED_ORDERS_FAILURE, payload: error });

export const fetchCancelledOrdersRequest = () => ({ type: FETCH_CANCELLED_ORDERS_REQUEST });
export const fetchCancelledOrdersSuccess = (orders) => ({ type: FETCH_CANCELLED_ORDERS_SUCCESS, payload: orders });
export const fetchCancelledOrdersFailure = (error) => ({ type: FETCH_CANCELLED_ORDERS_FAILURE, payload: error });

export const fetchUpcomingOrdersRequest = () => ({ type: FETCH_UPCOMING_ORDERS_REQUEST });
export const fetchUpcomingOrdersSuccess = (orders) => ({ type: FETCH_UPCOMING_ORDERS_SUCCESS, payload: orders });
export const fetchUpcomingOrdersFailure = (error) => ({ type: FETCH_UPCOMING_ORDERS_FAILURE, payload: error });

export const fetchViewOrderRequest = () => ({ type: FETCH_VIEW_ORDER_REQUEST });
export const fetchViewOrderSuccess = (ordernew) => ({ type: FETCH_VIEW_ORDER_SUCCESS, payload: ordernew });
export const fetchViewOrderFailure = (error) => ({ type: FETCH_VIEW_ORDER_FAILURE, payload: error });


export const cancelOrderRequest = () => ({ type: CANCEL_ORDER_REQUEST });
export const cancelOrderSuccess = () => ({ type: CANCEL_ORDER_SUCCESS });
export const cancelOrderFailure = (error) => ({ type: CANCEL_ORDER_FAILURE, payload: error });


export const fetchAllOrders = (selectedFilter) => async (dispatch) => {
    try {
        dispatch(fetchAllOrdersRequest());
        const token = localStorage.getItem("EM_TOKEN");
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };
        const response = await axios.get(`${process.env.REACT_APP_WEB_URL}/vendor/order-management?filter=${selectedFilter}`, config);
        dispatch(fetchAllOrdersSuccess(response.data));
    } catch (error) {
        dispatch(fetchAllOrdersFailure(error.message));
    }
}

// Implement similar async action creators for other types of orders
export const fetchCompletedOrders = (selectedFilter) => async (dispatch) => {
    try {
        dispatch(fetchCompletedOrdersRequest());
        // Make API call to fetch completed orders
        // Update the endpoint and headers as needed
        const emUser = JSON.parse(localStorage.getItem("EM_User"));
        const Id = emUser.data?._id;
        const token = localStorage.getItem("EM_TOKEN");
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };
        const response = await axios.get(`${process.env.REACT_APP_WEB_URL}/vendor/order-management/all/${Id}?status=completed&filter=${selectedFilter}`, config);
        dispatch(fetchCompletedOrdersSuccess(response.data));
    } catch (error) {
        dispatch(fetchCompletedOrdersFailure(error.message));
    }
}

export const fetchCancelOrders = (selectedFilter) => async (dispatch) => {
    try {
        dispatch(fetchCancelledOrdersRequest());
        const emUser = JSON.parse(localStorage.getItem("EM_User"));
        const Id = emUser.data?._id;
        const token = localStorage.getItem("EM_TOKEN");
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };
        const response = await axios.get(`${process.env.REACT_APP_WEB_URL}/vendor/order-management/all/${Id}?status=cancelled&filter=${selectedFilter}`, config);
        dispatch(fetchCancelledOrdersSuccess(response.data));
    } catch (error) {
        dispatch(fetchCancelledOrdersFailure(error.message));
    }
}


export const fetchUpcomingOrders = (selectedFilter) => async (dispatch) => {
    try {
        dispatch(fetchUpcomingOrdersRequest());
        const emUser = JSON.parse(localStorage.getItem("EM_User"));
        const Id = emUser.data?._id;
        const token = localStorage.getItem("EM_TOKEN");
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };
        const response = await axios.get(`${process.env.REACT_APP_WEB_URL}/vendor/order-management/all/${Id}?status=upcoming&filter=${selectedFilter}`, config);
        dispatch(fetchUpcomingOrdersSuccess(response.data));
    } catch (error) {
        dispatch(fetchUpcomingOrdersFailure(error.message));
    }
}



export const fetchViewOrder = (orderId) => async (dispatch) => {
    try {
        dispatch(fetchViewOrderRequest());
        const token = localStorage.getItem("EM_TOKEN");
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };
        const response = await axios.get(`${process.env.REACT_APP_WEB_URL}/vendor/order-management/${orderId}`, config);
        dispatch(fetchViewOrderSuccess(response.data));
    } catch (error) {
        dispatch(fetchViewOrderFailure(error.message));
    }
};


export const cancelOrder = (orderId, reason) => async (dispatch) => {
    try {
        dispatch(cancelOrderRequest());
        const token = localStorage.getItem("EM_TOKEN");
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        };
        const response = await axios.put(`${process.env.REACT_APP_WEB_URL}/vendor/order-management/cancel/${orderId}`, { reason }, config);
        dispatch(cancelOrderSuccess(response.data));
    } catch (error) {
        dispatch(cancelOrderFailure(error.message));
    }
};