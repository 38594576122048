import { ADD_ADDON_SECTION, EDIT_ADDON_SECTION } from '../actions/actiontypes';


const initialState = {
    addonSections: [],
    editAddonSections: [],
};

const addonSectionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_ADDON_SECTION':
            return {
                ...state,
                addonSections: [...state.addonSections, action.payload],
            };
        case 'EDIT_ADDON_SECTION':
            return {
                ...state,
                addonSections: [...state.editAddonSections, action.payload],
            };
        case 'CLEAR_ADDON_SECTION':
            return {
                ...state,
                addonSections: [],
                editAddonSections: [],
            };

        default:
            return state;
    }
};

export default addonSectionsReducer;
