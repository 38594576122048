import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import TranslateOutlinedIcon from '@mui/icons-material/TranslateOutlined';
import "../../../assert/css/languageComponent.css";
import { Checkbox, FormControlLabel, FormControl } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

const useStyles = makeStyles((theme) => ({
    mainBox: {
        height: "100vh",
        width: "60%",
        background: "black",
        position: "absolute",
        left: "0",
        top: "0",
        opacity: "0.7"
    },
    whiteBox: {
        position: "absolute",
        right: "0",
        top: "0",
        height: "100vh",
        width: "40%",
        background: "white",
        opacity: "1" ,
        minHeight: "400px", 
        overflowY: "auto"
    },
    closeArrowBox: {
        padding: "40px",
        marginTop: "50px !Important"
    },
    editProfileText: {
        fontFamily: "Mulish !Important",
        fontWeight: "700 !Important",
        fontSize: "14px !Important",
        marginLeft: "15px !Important",
        marginTop: "2px !Important"
    },
    hover: {
        "&:hover": {
            cursor: "pointer",
            opacity: "0.7"
        }
    },
    w100: {
        width: "100% !Important"
    },
    p55: {
        padding: "55px !Important"
    },
    text: {
        fontFamily: "Rubik !Important",
        fontWeight: "500 !Important",
        fontSize: "14px !Important",
        lineHeight: "20px !Important",
        color: 'rgba(65, 69, 79, 1) !Important',
        marginTop : "10px !Important" ,
        marginLeft : "30px !Important"
    },
    opacityHr: {
        opacity: "0.2",
        width: "100%",
        margin: "30px 13px 30px 25px !Important"
      },
      pt10 : {
        paddingTop : "10px !Important"
      },
      flex : {
        display : "flex !Important"
      },
      justifyEnd : {
        justifyContent : "end"
      }
}))


function Language({ handleLanguage }) {
    const classes = useStyles();
    const [selectedValue, setSelectedValue] = useState('');

  const handleChange = (event) => {
    setSelectedValue(event.target.value)
  };

    return (
        <>
            <Box className={classes.mainBox}>
            </Box>
            <Box className={classes.whiteBox}>
                <Grid spacing={1} container className={classes.closeArrowBox}>
                    <CloseOutlinedIcon className={classes.hover} onClick={() => { handleLanguage() }} />
                    <Typography className={classes.editProfileText}>Language</Typography>
                </Grid>
                <Grid className={`${classes.w100} ${classes.p55}`}>
                    <Grid spacing={1} container>
                        <Grid md={1}>
                            <TranslateOutlinedIcon style={{ color: 'rgba(0, 142, 122, 1)' }} />
                        </Grid>
                        <Grid md={11} spacing={1} container >
                            <Grid md={10}>
                                <Typography className={classes.text}>English</Typography>
                            </Grid>
                            <Grid md={2} className={`${classes.pt10} ${classes.flex} ${classes.justifyEnd}`}>
                                <FormControl component="fieldset">
                                    <FormControlLabel
                                        value="option1"
                                        control={
                                            <Checkbox
                                                icon={<span className="custom-checkbox" />}
                                                checkedIcon={<CheckIcon className="custom-checkbox-checked-icon" />}
                                                checked={selectedValue === 'option1'}
                                                onChange={handleChange}
                                            />
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <hr className={classes.opacityHr}/>
                        </Grid>
                    </Grid>
                    <Grid spacing={1} container >
                        <Grid md={1}>
                            <TranslateOutlinedIcon style={{ color: 'rgba(0, 142, 122, 1)' }} />
                        </Grid>
                        <Grid md={11} spacing={1} container>
                            <Grid md={10}>
                                <Typography className={classes.text}>Arabic</Typography>
                            </Grid>
                            <Grid md={2} className={`${classes.pt10} ${classes.flex} ${classes.justifyEnd}`}>
                                <FormControl component="fieldset">
                                    <FormControlLabel
                                        value="option2"
                                        control={
                                            <Checkbox
                                                icon={<span className="custom-checkbox" />}
                                                checkedIcon={<CheckIcon className="custom-checkbox-checked-icon" />}
                                                checked={selectedValue === 'option2'}
                                                onChange={handleChange}
                                            />
                                        }
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default Language