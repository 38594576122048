import React, { useEffect, useState } from "react";
import useStyles from "./style";
import { Box, Grid } from "@mui/material";
import Navbar from "../../components/user/Navbar";
import { getUserPolicy } from "../../api/userApi";
const AllPolicies = () => {
  const classes = useStyles();
  const [activeIndex, setActiveIndex] = useState(null);
  const [policy, setPolicy] = useState([]);
  const token = localStorage.getItem("EM_TOKEN");

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  useEffect(() => {
    getUserPolicy(token)
      .then((response) => {
        let result = response?.data?.policy || [];
        setPolicy(result);
      })
      .catch((err) => {
        console.log("Policy Error:", err);
      });
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Navbar />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ backgroundColor: "rgba(251, 251, 251, 1)", minHeight: "100vh" }}
        >
          <Box component={"div"} className="container-lg my-lg-5 my-md-4 my-3">
            <h3 className={`${classes.pageHeading} mb-lg-5 mb-lg-4 mb-3 `}>
              {" "}
              All Policies
            </h3>
            <Box
              component={"div"}
              className={
                "border border-light-subtle px-lg-5 px-md-4 px-3 py-lg-5 py-md-4 py-3 bg-white"
              }
            >
              {policy.map((dt, index) => (
                <div
                  className={`accordion accordion-flush ${classes.accordionDesign}`}
                  id={`accordionFlushExample-${index}`}
                  key={index}
                >
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id={`flush-heading${index}`}
                    >
                      <button
                        className={`accordion-button ${
                          activeIndex !== index ? "collapsed" : ""
                        }`}
                        type="button"
                        onClick={() => handleToggle(index)}
                        aria-expanded={activeIndex === index ? "true" : "false"}
                        aria-controls={`flush-collapse${index}`}
                      >
                        {dt?.title}
                      </button>
                    </h2>
                    <div
                      id={`flush-collapse${index}`}
                      className={`accordion-collapse collapse ${
                        activeIndex === index ? "show" : ""
                      }`}
                      aria-labelledby={`flush-heading${index}`}
                      data-bs-parent={`#accordionFlushExample-${index}`}
                    >
                      <div className="accordion-body">{dt?.description}</div>
                    </div>
                  </div>
                </div>
              ))}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default AllPolicies;
