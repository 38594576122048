import React, { useEffect, useRef, useState } from "react";
import "./LoginSignupDefault.scss";
// import Logo from '../../assert/image/logo.png'
import {
  Box,
  Button,
  FormGroup,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import LeftLogo from "./Component/LeftLogo";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bankDetailsValidation, contactDetailsValidation } from "./validation";
import { makeStyles } from "@material-ui/core/styles";
import {
  addBankDetails,
  addContactDetails,
} from "../../redux/actions/bankActions";
import PhoneInput from "react-phone-input-2";
import LoaderComponent from "../../vendor/Content/Loader/loader";
import ErrorMessage from "../../utils/ErrorMessage";

const useStyles = makeStyles((theme) => ({
  errors: {
    color: "red",
  },
  margin50: {
    marginTop: "50px !Important",
  },
  uploadButton: {
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: "pointer",
  },
  flexRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

const SignUpContactDetails = () => {
  const navigate = useNavigate();
  const vendorData = useSelector((state) => state.companyReducer.vendorDetail);
  const classes = useStyles();
  const [selectedFileName, setSelectedFileName] = useState(""); // State for file name
  const [selectedFile, setSelectedFile] = useState(null);
  const [contactDetails, setContactDetails] = useState({});
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false); // State variable for loader

  const {
    register,
    control,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(contactDetailsValidation),
    mode: "onBlur",
  });
  const formData1 = new FormData();

  const formData = new FormData();
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const handlePhoneChange = (value) => {
    setValue("contact_number", value);
  };
  const handleFileChange = (files) => {
    console.log("files:", files);

    if (files?.length > 0) {
      const file = files[0]; // Access the first file object in the array
      setSelectedFileName(file.name);
      setSelectedFile(file);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setContactDetails((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));

    preventLeadingSpaces(e, (value) => setValue(name, value));

    clearErrors(name); // Clear errors when user starts typing
  };

  useEffect(() => {
    // Check working hours
    if (selectedFile) {
      setError("contact_id", {
        type: "",
        message: "",
      });
    }
  }, [selectedFile]);

  const formSubmitNext = async (data) => {
    setIsLoading(true);
    try {
      Object.entries(data).forEach(([key, value]) => {
        if (key !== "contact_id") {
          formData.append(key, value);
        }
      });

      if (selectedFile) {
        formData.append("contact_id", selectedFile);
      } else {
        setError("contact_id", {
          type: "manual",
          message: "Please select a file",
        });
        throw new Error("Please select a file");
      }
      const responseData = await dispatch(
        addContactDetails(formData, vendorData.token)
      );
      toast.success("Contact Details Saved Successfully.");
      navigate("/signup-bank-details");
    } catch (error) {
      if (error?.response?.data.contactDetail === true) {
        setTimeout(() => {
          navigate("/signup-bank-details");
        }, 6000);
      }
      toast.error(error?.response?.data?.message);
      console.error("Error submitting form:", error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBlur = async (fieldName) => {
    await trigger(fieldName);
  };

  const preventLeadingSpaces = (event, setValueFunc) => {
    const trimmedValue = event.target.value.replace(/^\s+/, "");
    setValueFunc(trimmedValue);
    clearErrors(event.target.name);
  };
  const [isFilled, setIsFilled] = useState(false);
  return (
    <Box className="login-signup-default">
      <Box className="d-flex flex-wrap">
        <LeftLogo />
        <Box className="col-xxl-7 col-sm-8 col-12 right-box-outer">
          {isLoading ? (
            <LoaderComponent />
          ) : (
            <Box className="container h-100 d-flex align-items-lg-center justify-content-center py-4">
              <Box className="right">
                <Box className="tagline">"Bring Your Event to Life"</Box>
                <h1>Contact Details</h1>
                <Box
                  component="form"
                  className="form-box height-450 vendor-form"
                  onSubmit={handleSubmit(formSubmitNext)}
                >
                  <TextField
                    id="filled-basic"
                    {...register("company_email")}
                    label="Company Email"
                    type="text"
                    onChange={handleChange}
                    name="company_email"
                    className={`customInputFilledDesign`}
                    variant="filled"
                  />
                  <ErrorMessage message={errors.company_email?.message} />

                  <TextField
                    id="filled-basic"
                    {...register("website")}
                    label="Company Website"
                    type="text"
                    onChange={handleChange}
                    name="website"
                    className={`customInputFilledDesign  `}
                    variant="filled"
                  />
                  <ErrorMessage message={errors.website?.message} />

                  <TextField
                    id="filled-basic"
                    {...register("social_media_account")}
                    label="Social Media Account"
                    type="text"
                    onChange={handleChange}
                    name="social_media_account"
                    className={`customInputFilledDesign  `}
                    variant="filled"
                  />
                  <ErrorMessage
                    message={errors.social_media_account?.message}
                  />

                  <TextField
                    id="filled-basic"
                    {...register("contact_name")}
                    label="Contact Person Name"
                    type="text"
                    onChange={handleChange}
                    name="contact_name"
                    className={`customInputFilledDesign  `}
                    variant="filled"
                  />

                  <ErrorMessage message={errors.contact_name?.message} />

                  <Controller
                    name="contact_number"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <>
                        <PhoneInput
                          country={`${isFilled ? "ae" : ""}`} // 'ae' will be added in the future
                          value={field.value}
                          containerClass={`customInputFilledDesign mobileNumberDesign ${
                            isFilled ? "filled-input" : ""
                          }`}
                          inputClass="phone-input-field"
                          onChange={(value) => {
                            field.onChange(value); // Update the form state
                            handlePhoneChange(value); // Update the local state
                            setIsFilled(!!value); // Set isFilled to true if value is not empty
                          }}
                          onFocus={() => setIsFilled(true)} // Add filled-input class on focus◘
                          inputProps={{
                            name: "contact_number",
                            id: "contact_number",
                            required: false,
                            autoFocus: false,
                            autoComplete: "off", // Disable autocomplete
                          }}
                          placeholder=" "
                        />
                        {/* <InputLabel htmlFor="contact_number">
                            Contact Person Phone Number
                          </InputLabel> */}
                      </>
                    )}
                  />
                  <ErrorMessage message={errors.contact_number?.message} />

                  <TextField
                    id="filled-basic"
                    {...register("contact_position")}
                    label="Contact Person Designation"
                    type="text"
                    onChange={handleChange}
                    name="contact_position"
                    className={`customInputFilledDesign  `}
                    variant="filled"
                  />
                  {/* <ErrorMessage message={errors.contact_position?.message} />

                  <FormGroup
                    // className={`customInputFilledDesign input-box inputNumber bg-white d-flex flex-row align-items-center justify-content-between`}
                  >
                    <Box
                      className="fileUploadBox"
                      component={"div"}
                      sx={{ width: "calc(100% - 60px)" }}
                    >
                      <input
                        className="d-none"
                        type="file"
                        {...register("contact_id")}
                        id="contact_id"
                        autoComplete="off"
                        name="contact_id"
                        accept=".JPG, .jpg, .png"
                        onChange={(e) => handleFileChange(e.target.files)}
                        ref={fileInputRef}
                      />
                      <input
                        type="text"
                        value={selectedFileName}
                        readOnly
                        placeholder="Contact Person ID Proof"
                      />
                      <label htmlFor="contact_id">
                        Contact Person ID Proof
                      </label>
                    </Box>
                    <Box component={"div"} sx={{ width: "50px" }}>
                      <Button
                        fullWidth
                        variant="contained"
                        component="span"
                        onClick={handleButtonClick}
                        sx={{
                          background: "#fff !important",
                          width: "40px",
                          height: "40px",
                          minWidth: "40px",
                          maxWidth: "40px",
                          borderRadius: "50%",
                          padding: "5px !important",
                        }}
                      >
                        <svg
                          width="16"
                          height="22"
                          viewBox="0 0 16 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5 7.25H3.5C2.90326 7.25 2.33097 7.48705 1.90901 7.90901C1.48705 8.33097 1.25 8.90326 1.25 9.5V18.5C1.25 19.0967 1.48705 19.669 1.90901 20.091C2.33097 20.5129 2.90326 20.75 3.5 20.75H12.5C13.0967 20.75 13.669 20.5129 14.091 20.091C14.5129 19.669 14.75 19.0967 14.75 18.5V9.5C14.75 8.90326 14.5129 8.33097 14.091 7.90901C13.669 7.48705 13.0967 7.25 12.5 7.25H11M11 4.25L8 1.25M8 1.25L5 4.25M8 1.25V14"
                            stroke="#757575"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </Button>
                    </Box>
                  </FormGroup> */}
                  <ErrorMessage message={errors.contact_id?.message} />

                  <TextField
                    id="filled-basic"
                    label="Contact Person ID Proof"
                    name="profileImage"
                    variant="filled"
                    value={selectedFileName}
                    onBlur={() => handleBlur("profileImage")}
                    className="customInputFilledDesign"
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <input
                            id="profileImage"
                            type="file"
                            style={{ display: "none" }}
                            onBlur={() => handleBlur("profileImage")}
                            onChange={(e) => {
                              // handleChangeImage(e);
                              // setinTouched(true);
                              handleFileChange(e.target.files);
                            }}
                            accept="image/*"
                          />
                          <label htmlFor="profileImage">
                            <IconButton
                              component="span"
                              sx={{
                                width: "38px",
                                boxShadow:
                                  "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
                                "&:hover": {
                                  boxShadow:
                                    "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
                                },
                                position: "relative",
                                bottom: "8px",
                                backgroundColor: "#fff",
                              }}
                            >
                              <svg
                                width="16"
                                height="22"
                                viewBox="0 0 16 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5 7.25H3.5C2.90326 7.25 2.33097 7.48705 1.90901 7.90901C1.48705 8.33097 1.25 8.90326 1.25 9.5V18.5C1.25 19.0967 1.48705 19.669 1.90901 20.091C2.33097 20.5129 2.90326 20.75 3.5 20.75H12.5C13.0967 20.75 13.669 20.5129 14.091 20.091C14.5129 19.669 14.75 19.0967 14.75 18.5V9.5C14.75 8.90326 14.5129 8.33097 14.091 7.90901C13.669 7.48705 13.0967 7.25 12.5 7.25H11M11 4.25L8 1.25M8 1.25L5 4.25M8 1.25V14"
                                  stroke="#757575"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </IconButton>
                          </label>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <ErrorMessage message={errors.profileImage?.message} />

                  <FormGroup className="submit-box mt-4">
                    <Box className="d-flex flex-wrap gap-2 gap-xxl-4">
                      <button
                        className="default-btn white-bg"
                        onClick={() => {
                          navigate("/loginv2");
                        }}
                      >
                        Cancel
                      </button>
                      <button className="default-btn green-bg" type="submit">
                        Next
                      </button>
                    </Box>
                  </FormGroup>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SignUpContactDetails;
