// orderReducer.js
import {
    FETCH_ALL_ORDERS_REQUEST, FETCH_ALL_ORDERS_SUCCESS, FETCH_ALL_ORDERS_FAILURE,
    FETCH_COMPLETED_ORDERS_REQUEST, FETCH_COMPLETED_ORDERS_SUCCESS, FETCH_COMPLETED_ORDERS_FAILURE,
    FETCH_CANCELLED_ORDERS_REQUEST, FETCH_CANCELLED_ORDERS_SUCCESS, FETCH_CANCELLED_ORDERS_FAILURE,
    FETCH_UPCOMING_ORDERS_REQUEST, FETCH_UPCOMING_ORDERS_SUCCESS, FETCH_UPCOMING_ORDERS_FAILURE, FETCH_VIEW_ORDER_REQUEST, FETCH_VIEW_ORDER_SUCCESS, FETCH_VIEW_ORDER_FAILURE, CANCEL_ORDER_REQUEST, CANCEL_ORDER_SUCCESS, CANCEL_ORDER_FAILURE
} from '../actions/actiontypes';

const initialState = {
    allOrders: [],
    completedOrders: [],
    cancelledOrders: [],
    upcomingOrders: [],
    order: {},
    cancelledOrderId: null,
    loading: false,
    error: null
};

const orderReducer = (state = initialState, action) => {
    switch (action.type) {
        // Reducers for fetching all orders
        case FETCH_ALL_ORDERS_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_ALL_ORDERS_SUCCESS:
            return { ...state, loading: false, allOrders: action.payload };
        case FETCH_ALL_ORDERS_FAILURE:
            return { ...state, loading: false, error: action.payload };

        // Reducers for fetching completed orders
        case FETCH_COMPLETED_ORDERS_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_COMPLETED_ORDERS_SUCCESS:
            return { ...state, loading: false, completedOrders: action.payload };
        case FETCH_COMPLETED_ORDERS_FAILURE:
            return { ...state, loading: false, error: action.payload };

        // Reducers for fetching cancelled orders
        case FETCH_CANCELLED_ORDERS_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_CANCELLED_ORDERS_SUCCESS:
            return { ...state, loading: false, cancelledOrders: action.payload };
        case FETCH_CANCELLED_ORDERS_FAILURE:
            return { ...state, loading: false, error: action.payload };

        // Reducers for fetching upcoming orders
        case FETCH_UPCOMING_ORDERS_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_UPCOMING_ORDERS_SUCCESS:
            return { ...state, loading: false, upcomingOrders: action.payload };
        case FETCH_UPCOMING_ORDERS_FAILURE:
            return { ...state, loading: false, error: action.payload };

        case FETCH_VIEW_ORDER_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_VIEW_ORDER_SUCCESS:
            return { ...state, loading: false, ordernew: action.payload };
        case FETCH_VIEW_ORDER_FAILURE:
            return { ...state, loading: false, error: action.payload };

        case CANCEL_ORDER_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                cancelledOrderId: null
            };
        case CANCEL_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                cancelledOrderId: action.payload
            };
        case CANCEL_ORDER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };


        default:
            return state;
    }
}

export default orderReducer;
