import { useState, useEffect } from "react";

import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ContactsIcon from "@mui/icons-material/Contacts";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import InfoIcon from "@mui/icons-material/Info";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

export const profile = [
  {
    nameKey: "my_profile",
    component: (
      <PermIdentityOutlinedIcon style={{ color: "rgba(0, 142, 122, 1)" }} />
    ),
    path: "/vendor/myProfile",
  },
  {
    nameKey: "contact_details",
    component: <ContactsIcon style={{ color: "rgba(0, 142, 122, 1)" }} />,
    path: "/vendor/contact-details",
  },
  {
    nameKey: "bank_details",
    component: <AccountBalanceIcon style={{ color: "rgba(0, 142, 122, 1)" }} />,
    path: "/vendor/bank-details",
  },
  {
    nameKey: "customer_cares",
    component: <SupportAgentIcon style={{ color: "rgba(0, 142, 122, 1)" }} />,
    path: "/vendor/customer-care",
  },
  {
    nameKey: "AboutEvent",
    component: (
      <ErrorOutlineOutlinedIcon style={{ color: "rgba(0, 142, 122, 1)" }} />
    ),
    path: "/about",
  },
];
