import { ActionTypes } from "../actions/actiontypes"
const initialState={
    vendorDetail:[],
    companyDetail:[],
    bankDetail:[],
}

export const companyReducer = (state=initialState, action) =>{
    switch(action.type){
        case  ActionTypes.EMPTY_VENDOR_BASIC_DETAILS:
            return {
                ...state,
                vendorDetail:[]
            }
        case ActionTypes.ADD_VENDOR_BASIC_DETAILS:
            return {
                ...state,
                vendorDetail:action.payload
            }
        case ActionTypes.ADD_COMPANY_BASIC_DETAILS:
            return {
                ...state,
                companyDetail:action.payload
            }
        case ActionTypes.ADD_COMPANY_BANK_DETAILS:
            return {
                ...state,
                bankDetail:action.payload
            }
        default:
            return state;
    }
}