// reducers/productReducer.js

import { ADD_PRODUCT_ITEM_SUCCESS, ADD_PRODUCT_ITEM_FAILURE, FETCH_PRODUCT_BY_ID_FAILURE, FETCH_PRODUCT_BY_ID_SUCCESS, UPDATE_PRODUCT_ITEM_SUCCESS, UPDATE_PRODUCT_ITEM_FAILURE } from '../actions/actiontypes';

const initialState = {
    product: null,
    loading: false,
    error: null,
};

const productReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_PRODUCT_ITEM_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                // Handle success action payload if needed
            };
        case ADD_PRODUCT_ITEM_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case FETCH_PRODUCT_BY_ID_SUCCESS:
            return {
                ...state,
                product: action.payload,
                loading: false,
                error: null,
            };
        case FETCH_PRODUCT_BY_ID_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case UPDATE_PRODUCT_ITEM_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                // Handle success action payload if needed
            };
        case UPDATE_PRODUCT_ITEM_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        default:
            return state;
    }
};

export default productReducer;
