// reducer.js
import { PASSWORD_REQUEST, PASSWORD_SUCCESS, PASSWORD_FAILURE } from '../actions/actiontypes';

const initialState = {
    loading: false,
    message: '',
    error: ''
};

const createPasswordReducer = (state = initialState, action) => {
    switch (action.type) {
        case PASSWORD_REQUEST:
            return {
                ...state,
                loading: true
            };
        case PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.payload,
                error: ''
            };
        case PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                message: '',
                error: action.payload
            };
        default:
            return state;
    }
};

export default createPasswordReducer;
