import {
    FETCH_ALL_REPORTS_REQUEST, FETCH_ALL_REPORTS_SUCCESS, FETCH_ALL_REPORTS_FAILURE,
    FETCH_COMPLETED_REPORTS_REQUEST, FETCH_COMPLETED_REPORTS_SUCCESS, FETCH_COMPLETED_REPORTS_FAILURE,
    FETCH_CANCELLED_REPORTS_REQUEST, FETCH_CANCELLED_REPORTS_SUCCESS, FETCH_CANCELLED_REPORTS_FAILURE,
    FETCH_UPCOMING_REPORTS_REQUEST, FETCH_UPCOMING_REPORTS_SUCCESS, FETCH_UPCOMING_REPORTS_FAILURE,
    FETCH_VIEW_ORDER_REQUEST, FETCH_VIEW_ORDER_SUCCESS, FETCH_VIEW_ORDER_FAILURE,
    CANCEL_ORDER_REQUEST, CANCEL_ORDER_SUCCESS, CANCEL_ORDER_FAILURE,
    DOWNLOAD_ITEM_REPORT_REQUEST, DOWNLOAD_ITEM_REPORT_SUCCESS, DOWNLOAD_ITEM_REPORT_FAILURE,
    DOWNLOAD_FEEDBACK_REPORT_REQUEST, DOWNLOAD_FEEDBACK_REPORT_SUCCESS, DOWNLOAD_FEEDBACK_REPORT_FAILURE
} from '../actions/actiontypes';

const initialState = {
    allReports: [],
    completedReports: [],
    cancelledReports: [],
    upcomingReports: [],
    report: {},
    cancelledOrderId: null,
    loading: false,
    error: null
};

const orderReducer = (state = initialState, action) => {
    switch (action.type) {
        // Reducers for fetching all reports
        case FETCH_ALL_REPORTS_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_ALL_REPORTS_SUCCESS:
            return { ...state, loading: false, allReports: action.payload };
        case FETCH_ALL_REPORTS_FAILURE:
            return { ...state, loading: false, error: action.payload };

        // Reducers for fetching completed reports
        case FETCH_COMPLETED_REPORTS_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_COMPLETED_REPORTS_SUCCESS:
            return { ...state, loading: false, completedReports: action.payload };
        case FETCH_COMPLETED_REPORTS_FAILURE:
            return { ...state, loading: false, error: action.payload };

        // Reducers for fetching cancelled reports
        case FETCH_CANCELLED_REPORTS_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_CANCELLED_REPORTS_SUCCESS:
            return { ...state, loading: false, cancelledReports: action.payload };
        case FETCH_CANCELLED_REPORTS_FAILURE:
            return { ...state, loading: false, error: action.payload };

        // Reducers for fetching upcoming reports
        case FETCH_UPCOMING_REPORTS_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_UPCOMING_REPORTS_SUCCESS:
            return { ...state, loading: false, upcomingReports: action.payload };
        case FETCH_UPCOMING_REPORTS_FAILURE:
            return { ...state, loading: false, error: action.payload };

        case FETCH_VIEW_ORDER_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_VIEW_ORDER_SUCCESS:
            return { ...state, loading: false, order: action.payload };
        case FETCH_VIEW_ORDER_FAILURE:
            return { ...state, loading: false, error: action.payload };

        case CANCEL_ORDER_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                cancelledOrderId: null
            };
        case CANCEL_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                cancelledOrderId: action.payload
            };
        case CANCEL_ORDER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        // Reducers for downloading item report
        case DOWNLOAD_ITEM_REPORT_REQUEST:
            return { ...state, loading: true, error: null };
        case DOWNLOAD_ITEM_REPORT_SUCCESS:
            // Add logic to handle success, if needed
            return { ...state, loading: false };
        case DOWNLOAD_ITEM_REPORT_FAILURE:
            // Add logic to handle failure, if needed
            return { ...state, loading: false, error: action.payload };

        // Reducers for downloading feedback report
        case DOWNLOAD_FEEDBACK_REPORT_REQUEST:
            return { ...state, loading: true, error: null };
        case DOWNLOAD_FEEDBACK_REPORT_SUCCESS:
            // Add logic to handle success, if needed
            return { ...state, loading: false };
        case DOWNLOAD_FEEDBACK_REPORT_FAILURE:
            // Add logic to handle failure, if needed
            return { ...state, loading: false, error: action.payload };



        default:
            return state;
    }
}

export default orderReducer;
